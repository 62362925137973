import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { EnvironmentConfigContext } from "../../config/Environment";
import TagManager from "react-gtm-module";
import {
  GetOrCreateSessionAuthContext,
  UserAuthContext,
} from "../../config/UserAuthContext";

import Cookies from "js-cookie";
import {
  Context,
  isAuthenticatedWithValidToken,
  SecureCookieAttributes,
  navigateTo,
  SetAuthTokenAsCookies,
} from "../../config/UserAuthContext";
import {
  MixPanelAnalyticsContext,
  Properties,
} from "../../components/analytics/Analytics";
import AccountServiceClient from "../../lib/ApiFunction";
import { toast } from "react-toastify";

// Images //
import BenAndFriends from "../../global-assets/assets/Ben-And-Friends.png";
import QRCode from "../../global-assets/assets/Download-App-QR.png";
import Friends2 from "../../global-assets/assets/Download-App-Friends-2.png";
import Friends3 from "../../global-assets/assets/Download-App-Friends-3.png";
import DownloadAppStar from "../../global-assets/assets/Download-App-Star.svg";
import AppStore from "../../global-assets/assets/AStore.png";
import GooglePlay from "../../global-assets/assets/GPlay.png";
import arrow from "../../global-assets/assets/Caret.svg";
import Button from "../../components/common/Button";

/**
 * Subscription Complete Landing Page
 * @constructor
 */

interface Flextrack {
  invoked?: boolean;
  methods?: string[];
  factory?: any;
  init?: (advertiserId: string) => void;
  getClick?: () => void;
  track?: (data: any) => void;
  [key: string]: any;
}

declare global {
  interface Window {
    flextrack: Flextrack;
  }
}

const DownloadApp = () => {
  const analyticsContext = useContext(MixPanelAnalyticsContext);
  const config = useContext(EnvironmentConfigContext);

  const appleURL = config?.AppStoreURL();
  const googleURL = config?.PlayStoreURL();

  const osType = navigator.userAgent;

  const initialUserContext = GetOrCreateSessionAuthContext();
  const [userContext, setUserContext] = useState(initialUserContext);
  const subscriptionInfo = userContext.subscription.sources;
  const { SubscriptionAPI } = AccountServiceClient();
  const location = useLocation();
  const GlobalUserId = Cookies.get("GlobalUserId");

  const getSubscription = async () => {
    if (!isAuthenticatedWithValidToken()) return;

    if (!userContext?.subscription.isActive) {
      try {
        const res = await SubscriptionAPI.get("");
        const updatedContext = new UserAuthContext();
        updatedContext.subscription = res.data;
        updatedContext.communicationsOptIn = userContext.communicationsOptIn;
        updatedContext.token = userContext.token;
        updatedContext.uuId = userContext.uuId;
        updatedContext.verifyEmail = userContext.verifyEmail;
        updatedContext.sso = userContext.sso;

        setUserContext(updatedContext);
        SetAuthTokenAsCookies(
          JSON.stringify(updatedContext),
          Cookies.get("EmailAddress") || ""
        );

        if (res.data.isActive) {
          Cookies.set(
            Context.UserContext,
            JSON.stringify(updatedContext),
            SecureCookieAttributes
          );

          toast.success(
            <div>
              <b>Success!</b> Your payment has been received and your account
              has been created successfully.
            </div>,
            {
              position: "top-center",
            }
          );
        }
      } catch (error) {
        console.error("Error fetching subscription data:", error);
      }
    }
  };

  useEffect(() => {
    getSubscription();

    let attribution = localStorage.getItem("tru-attribution") as string;
    let campaignAttribution: Properties = {};
    if (attribution) {
      campaignAttribution = JSON.parse(attribution) as Properties;
    }
    analyticsContext.track("Web: Download-App-Page-View", campaignAttribution);
  }, []);

  /**
   *
   * @param platform
   */
  const handleDownloadClick =
    (linkName: string, linkURL: string) => (e: any) => {
      e.preventDefault();
      TagManager.dataLayer({
        dataLayer: {
          event: "select_content",
          content_type: "link",
          os_type: osType,
          link_name: linkName,
          link_url: linkURL,
          plan_type: subscriptionInfo[0]?.productId || "none",
          custom_user_id: GlobalUserId || undefined,
        },
      });
      window.open(linkURL, "_blank");
    };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get("session_id");
    if (sessionId) {
      // User came from Stripe
      TagManager.dataLayer({
        dataLayer: {
          event: "subscription_success",
          page_name: "accounts: subscription success",
          page_type: "accounts",
          os_type: osType,
          plan_type: userContext.subscription.sources[0]?.productId ?? "none",
          custom_user_id: GlobalUserId || undefined,
        },
      });
      sessionStorage.setItem("hasSentSubscriptionSuccess", "true");
    } else {
      // User did not come from Stripe
      TagManager.dataLayer({
        dataLayer: {
          page_name: "accounts: subscription success",
          page_type: "accounts",
          os_type: osType,
          plan_type: userContext.subscription.sources[0]?.productId ?? "none",
          custom_user_id: GlobalUserId || undefined,
        },
      });
    }
  }, [location.search, osType, userContext, GlobalUserId]);

  return (
    <div id="test_thank_you_page" className="relative overflow-hidden">
      <div className="absolute bottom-0 right-0 z-[2] hidden 2xl:block">
        <img src={Friends3} alt="Ben and Friends" className="max-w-[500px]" />
      </div>
      <div className="mx-auto min-h-screen max-w-[900px] px-6 pb-12 pt-24 lg:px-0 lg:pt-32">
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_0%_100%,_#68e0cf_0%,_#1f9cff_15%,transparent_30%)] opacity-20" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_100%_0%,_#652c80_0%,transparent_40%)] opacity-60" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_100%_100%,_#20568b_0%,_#002952_25%,transparent_40%)] opacity-70" />
        <div className="flex flex-col items-center gap-8 text-center">
          <div className="flex flex-col items-center gap-4">
            <h1 className="text-transparent bg-gradient_text bg-clip-text text-center font-display text-4xl font-bold 2xl:max-w-[400px]">
              Welcome to the TruPlay Family!
            </h1>
            <h5 className="text-center font-body text-2xl font-normal text-white">
              Just a few simple steps to safe screen time.
            </h5>
            <div className="2xl:hidden">
              <img
                src={BenAndFriends}
                alt="Ben and Friends"
                className="-mb-[70px] -mt-[60px] w-[800px] sm:-my-[80px] md:-my-[110px] md:max-w-[1000px]"
              />
            </div>
          </div>

          <div
            className="relative flex w-full flex-col gap-4 rounded-[30px] bg-white/[6%] p-5 text-start shadow-[0_0_41px_-1px_#001b3666] md:p-8 lg:gap-8"
            id="step1"
          >
            <img
              src={DownloadAppStar}
              alt="star"
              className="absolute -top-[20px] right-[20px] max-h-[30px] max-w-[50px] md:hidden"
            />
            <div className="flex gap-3 md:w-full">
              <div className="text-transparent -mt-2 flex min-w-[70px] justify-center bg-gradient_text bg-clip-text font-display text-8xl font-bold">
                1
              </div>
              <div className="flex w-full items-center">
                <div className="flex flex-col gap-2">
                  <h6 className="font-display text-2xl font-bold text-white drop-shadow-[0_0_96px_#38a6d5bf]">
                    Download the app
                  </h6>
                  <p className="m-0 font-body text-base font-normal text-indigo-200">
                    Download TruPlay on your Apple or Android Devices.
                  </p>
                  <div className="hidden w-full items-center justify-start gap-2.5 md:flex">
                    <Link
                      id="auth_download_apple"
                      to={appleURL}
                      target="_blank"
                      onClick={(e) => handleDownloadClick("apple", appleURL)(e)}
                    >
                      <img
                        src={AppStore}
                        alt="Apple store"
                        className="max-h-12"
                      />
                    </Link>
                    <Link
                      id="auth_download_google_play"
                      to={googleURL}
                      target="_blank"
                      onClick={(e) =>
                        handleDownloadClick("google", googleURL)(e)
                      }
                    >
                      <img
                        src={GooglePlay}
                        alt="Google play"
                        className="max-h-12"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="hidden justify-end lg:block">
                <img
                  src={QRCode}
                  alt="Download the app"
                  className="max-w-[100px]"
                />
              </div>
            </div>
            <div className="flex w-full gap-2.5 md:hidden">
              <Link
                id="auth_download_apple"
                to={appleURL}
                target="_blank"
                onClick={(e) => handleDownloadClick("apple", appleURL)(e)}
              >
                <img src={AppStore} alt="Apple store" className="max-h-12" />
              </Link>
              <Link
                id="auth_download_google_play"
                to={googleURL}
                target="_blank"
                onClick={(e) => handleDownloadClick("google", googleURL)(e)}
              >
                <img src={GooglePlay} alt="Google play" className="max-h-12" />
              </Link>
            </div>
          </div>

          <div
            className="relative flex w-full gap-3 rounded-[30px] bg-white/[6%] p-5 text-start shadow-[0_0_41px_-1px_#001b3666] md:p-8"
            id="step2"
          >
            <div className="text-transparent -mt-2 flex min-w-[70px] justify-center bg-gradient_text bg-clip-text font-display text-8xl font-bold">
              2
            </div>
            <div>
              <h6 className="font-display text-2xl font-bold text-white drop-shadow-[0_0_96px_#38a6d5bf]">
                Sign In
              </h6>
              <p className="font-body text-base font-normal text-indigo-200">
                Choose 'Log In' and sign in with your account email and
                password.
              </p>
            </div>
          </div>

          <div
            className="relative flex w-full gap-3 rounded-[30px] bg-white/[6%] p-5 text-start shadow-[0_0_41px_-1px_#001b3666] md:p-8"
            id="step3"
          >
            <div className="text-transparent -mt-2 flex min-w-[70px] justify-center bg-gradient_text bg-clip-text font-display text-8xl font-bold">
              3
            </div>
            <div>
              <h6 className="font-display text-2xl font-bold text-white drop-shadow-[0_0_96px_#38a6d5bf]">
                Play in the Light!
              </h6>
              <p className="font-body text-base font-normal text-indigo-200">
                Enjoy safe and uplifting games.
              </p>
            </div>
          </div>

          <div
            className="relative flex w-full flex-col gap-4 rounded-[30px] bg-white/[6%] p-5 text-start shadow-[0_0_41px_-1px_#001b3666] md:p-8"
            id="step4"
          >
            <div className="flex justify-between">
              <div className="flex gap-3">
                <div className="text-transparent -mt-2 flex min-w-[70px] justify-center gap-2 bg-gradient_text bg-clip-text font-display text-8xl font-bold">
                  4
                </div>
                <div className="flex flex-col items-center justify-between md:flex-row">
                  <div>
                    <h6 className="font-display text-2xl font-bold text-white drop-shadow-[0_0_96px_#38a6d5bf]">
                      Refer a Friend, get $25!
                    </h6>
                    <p className="font-body text-base font-normal text-indigo-200">
                      Share TruPlay and earn cash!
                    </p>
                  </div>
                </div>
              </div>
              <div className="self-end">
                <Button
                  id="refer_a_friend_cta"
                  onClick={() => navigateTo("/affiliate", true)}
                  btnType="green"
                  className="hidden md:block"
                  rightIcon={
                    <img
                      src={arrow}
                      alt="right-arrow"
                      className="ml-3 w-5 font-bold"
                    />
                  }
                >
                  Refer A Friend
                </Button>
              </div>
            </div>
            <div className="w-full">
              <Button
                id="refer_a_friend_cta"
                onClick={() => navigateTo("/affiliate", true)}
                btnType="green"
                className="w-full sm:w-auto md:hidden"
                rightIcon={
                  <img
                    src={arrow}
                    alt="right-arrow"
                    className="ml-3 w-5 font-bold"
                  />
                }
              >
                Refer A Friend
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 z-[2] hidden 2xl:block">
        <img src={Friends2} alt="Friends Playing" className="max-w-[420px]" />
      </div>
    </div>
  );
};

export default DownloadApp;
