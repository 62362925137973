import { useState } from "react";

// Components //
import Button from "../common/Button";
import TextWithVideoSection from "../common/TextWithVideoSection";

// Assets //
import ThumbOurMission from "./assets/thumbnail_our_mission.webp";
import BlueCaretDown from "../../global-assets/assets/icon-caret-down-cerulean.svg";
import SectionHeader from "../common/SectionHeader";

export default function OurMission() {
  const [readMore, setReadMore] = useState<boolean>(false);
  const handleReadMore = () => {
    setReadMore(!readMore);
  };

  const contentText = (
    <>
      <p
        className={
          "relative m-0 overflow-hidden text-center text-lg font-normal text-white lg:!line-clamp-none lg:text-left lg:text-xl lg:font-semibold" +
          (readMore ? " line-clamp-none" : " line-clamp-6")
        }
      >
        TruPlay wants to give families a gaming alternative with safe,
        encouraging, and faith-building screen time.
        <br />
        <br />
        How can we compete with toxic big gaming companies? By building
        thoughtfully designed games with relatable characters and stories that
        reinforce biblical values.
        <br />
        <br />
        So we recruited from the likes of Electronic Arts, Warner Bros., Zynga,
        and Jam City who were excited to join the mission.
        <br />
        <br />
        <strong className="text-2xl">Now, we need your help!</strong>
      </p>
      <div className="flex items-center justify-center gap-4 lg:hidden lg:justify-end">
        <Button
          id="our_journey_read_more_btn"
          btnType="tertiaryAlt"
          onClick={handleReadMore}
          rightIcon={
            <img
              src={BlueCaretDown}
              alt="Caret"
              loading="lazy"
              className={
                "ml-4 h-6 w-6 duration-300" + (readMore ? " rotate-180" : "")
              }
            />
          }
        >
          {!readMore ? "Read More" : "Read Less"}
        </Button>
      </div>
    </>
  );

  return (
    <div className="flex flex-col gap-6 lg:gap-20">
      <div className="lg:hidden">
        <SectionHeader headerType="h2" headerText="Our Mission" />
      </div>
      <TextWithVideoSection
        headerText="Our Mission"
        headerTextColor="text-transparent bg-gradient_text bg-clip-text"
        hideHeaderOnMobile
        contentText={contentText}
        contentTextColor="text-white"
        videoUrl="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/crowdfunding_join_the_movement%20(720p).mp4"
        videoId="948925112"
        videoThumbnail={ThumbOurMission}
        videoShareUrl="https://vimeo.com/948925112"
        videoPosition="right"
        videoSectionTestId="our_mission_video"
        textPositionOnMobile="above"
        showAccentStars
        handleToggleReadMore
      />
    </div>
  );
}
