import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ApiInstance from "../../lib/ApiFunction";
import { InlineErrorMessage } from "../../components/notification/InlineErrorMessage/InlineErrorMessage";
import Cookies from "js-cookie";
import {
  Context,
  GetRememberMe,
  UserAuthContext,
  GetOrCreateSessionAuthContext,
} from "../../config/UserAuthContext";
import { GetContinueToQueryString, ContinueTo } from "../../lib/PassedParams";
import TagManager from "react-gtm-module";
import { MixPanelAnalyticsContext } from "../../components/analytics/Analytics";
import { ErrorCodeMap } from "../../lib/Constants";
import { Helmet } from "react-helmet";

// Firebase
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { useRemoteConfig } from "../../config/FBRemoteConfigContext";

import EmailIcon from "../../global-assets/assets/icon-email.svg";
import PasswordIcon from "../../global-assets/assets/icon-password.svg";
import eyeShowIcon from "../../global-assets/assets/icon-eye-show.svg";
import eyeHideIcon from "../../global-assets/assets/icon-eye-hide.svg";
import BackCaret from "../RegistrationFlow/assets/back-caret.svg";
import TopNavbar from "../../components/nav/TopNavbar/TopNavbar";
import Button from "../../components/common/Button";

/**
 * Login Page to authenticate users with valid email address on file
 *
 * @param props
 * @constructor
 */
interface LoginProps {
  setAuth: any;
  setLoginData: any;
  setIsEmailLogIn?: (isEmailLogIn: boolean) => void;
}

declare global {
  interface Window {
    dataLayer: any[];
  }
}

/**
 *
 * @param setAuth
 * @param setLoginData
 * @constructor
 */
export default function Login({
  setAuth,
  setLoginData,
  setIsEmailLogIn,
}: LoginProps) {
  const analyticsContext = useContext(MixPanelAnalyticsContext);
  let failedLoginAttempts: number = 0;

  const navigate = useNavigate();

  const { AccountServiceUserLogin } = ApiInstance();
  const emailInputRef: any = useRef(null);
  const passwordInputRef: any = useRef(null);

  const [email, setEmail] = useState<any>("");
  const [password, setPassword] = useState<any>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);
  const [isCallingAPI, setIsCallingAPI] = useState<boolean>(false);
  const [isRememberMe, setIsRememberMe] = useState<boolean>(false);
  const [isPasswordAutofilled, setIsPasswordAutofilled] =
    useState<boolean>(false);
  const osType = navigator.userAgent;

  const userContext = GetOrCreateSessionAuthContext();
  const subscriptionInfo = userContext.subscription.sources;
  const GlobalUserId = Cookies.get("GlobalUserId");

  const [loginError, setLoginError] = useState<string | null>(null);
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [isPasswordError, setIsPasswordError] = useState<boolean>(false);

  const [isCookiesBlocked, setIsCookiesBlocked] = useState<boolean>(false);

  const credsFromCookie: any = GetRememberMe();

  const firebaseFlags = useRemoteConfig();

  // With Free Trial available for the whole site, just adding this here
  // to ensure it's active
  Cookies.set(Context.FreeTrial, "true");

  useEffect(() => {
    const cookiesAvailable = () => {
      try {
        localStorage.setItem("test", "test");
        localStorage.removeItem("test");
        setIsCookiesBlocked(false);
        return true;
      } catch (e) {
        setIsCookiesBlocked(true);
        TagManager.dataLayer({
          dataLayer: {
            event: "cookies_error_shown",
            cookies_consent: "cookies_blocked",
            page_name: "Login",
            page_type: "Marketing",
            custom_user_id: GlobalUserId || undefined,
          },
        });
        return false;
      }
    };

    if (cookiesAvailable() && process.env.REACT_APP_ENVIRONMENT === "prod") {
      const script = document.createElement("script");
      script.src =
        "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=STmu7s";
      script.async = true;
      script.type = "text/javascript";
      document.body.appendChild(script);

      return () => {
        if (document.body.contains(script)) {
          document.body.removeChild(script);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (credsFromCookie) {
      const credentials = JSON.parse(credsFromCookie);
      if (credentials) {
        const { email } = credentials;
        setEmail(email);
        setIsRememberMe(true);
      }
    }
  }, [credsFromCookie]);

  useEffect(() => {
    if (passwordInputRef.current && passwordInputRef.current.value) {
      setIsPasswordAutofilled(true);
    }
  }, [password]);

  useEffect(() => {
    if (!password.length || !email.length) {
      setIsButtonActive(false);
    } else {
      setIsButtonActive(true);
    }
  }, [email.length, password.length]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        page_name: "accounts: login",
        page_type: "accounts",
        plan_type: subscriptionInfo[0] || "none",
        os_type: osType,
        custom_user_id: GlobalUserId || undefined,
      },
    });
  }, [osType, subscriptionInfo, GlobalUserId]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Firebase Auth
  const handleFirebaseLogIn = () => {
    // Handle Remember Me
    if (isRememberMe) {
      Cookies.set(Context.RememberMe, JSON.stringify({ email: email }));
    } else {
      Cookies.remove(Context.RememberMe);
    }

    setIsEmailError(false);
    setIsPasswordError(false);
    setLoginError("");

    setIsCallingAPI(true);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user: any = userCredential.user;
        Cookies.set(
          Context.AuthContext,
          JSON.stringify({ userID: user.uid, token: user.accessToken })
        );

        const userData: any = {
          communicationsOptIn: false,
          subscription: {
            isActive: false,
            expireTime: null,
            sources: Array(0),
          },
          token: user.accessToken,
          uuId: user.uid,
          verifyEmail: {
            isVerified: user.emailVerified,
            shouldShowEmailVerificationScreen: !user.emailVerified,
          },
        };
        Cookies.set(Context.UserContext, JSON.stringify(userData));
        Cookies.set("EmailAddress", email);

        // User is authenticated
        setAuth(true);

        setLoginData(userData);
        analyticsContext.track("Web: Login-Attempt-Successful");

        TagManager.dataLayer({
          dataLayer: {
            event: "login",
            plan_type: subscriptionInfo[0] || "none",
            os_type: osType,
            method: "email",
            custom_user_id: GlobalUserId || undefined,
          },
        });

        const currentAuthContext: UserAuthContext = userData;

        // look for 'continueto' param, go there instead if we have one
        if (ContinueTo()) {
          analyticsContext.track("Web: Redirect-After-Authentication");
          navigate(decodeURIComponent(ContinueTo() || ""));
        } else if (currentAuthContext.subscription.isActive) {
          analyticsContext.track("Web: Account-Setting-Viewed");
          navigate("/account-settings");
        } else {
          analyticsContext.track("Web: Select-Plan-Viewed");
          navigate("/select-plan");
        }

        setIsCallingAPI(false);
      })
      .catch((error) => {
        const fbErrorCode = error.code;
        const fbErrorMessage = error.message;
        const errorMessage = ErrorCodeMap[fbErrorCode]
          ? ErrorCodeMap[fbErrorCode]
          : fbErrorMessage;
        setLoginError(errorMessage);
        setIsCallingAPI(false);
      });
  };

  /**
   * Authenticate user
   * @param e
   */
  const handleOnSubmit = (e: any) => {
    // Handle Remember Me
    if (isRememberMe) {
      Cookies.set(Context.RememberMe, JSON.stringify({ email: email }));
    } else {
      Cookies.remove(Context.RememberMe);
    }

    setIsEmailError(false);
    setIsPasswordError(false);
    setLoginError("");

    let userCredentials = {
      emailAddress: email,
      password: password,
    };

    setIsCallingAPI(true);

    AccountServiceUserLogin.post("", userCredentials)
      .then((response) => {
        let { uuId, token } = response.data;

        Cookies.set(
          Context.AuthContext,
          JSON.stringify({ userID: uuId, token: token })
        );

        Cookies.set(Context.UserContext, JSON.stringify(response.data));

        Cookies.set("EmailAddress", email);

        // user is authenticated
        setAuth(true);

        setLoginData(response.data);
        analyticsContext.track("Web: Login-Attempt-Successful");

        TagManager.dataLayer({
          dataLayer: {
            event: "login",
            plan_type: subscriptionInfo[0] || "none",
            os_type: osType,
            method: "email",
            custom_user_id: GlobalUserId || undefined,
          },
        });

        let currentAuthContext: UserAuthContext = response.data;

        // look for 'continueto' param, go there instead if we have one
        if (ContinueTo()) {
          analyticsContext.track("Web: Redirect-After-Authentication");
          navigate(decodeURIComponent(ContinueTo() || ""));
        } else if (currentAuthContext.subscription.isActive) {
          analyticsContext.track("Web: Account-Setting-Viewed");
          navigate("/account-settings");
        } else {
          analyticsContext.track("Web: Select-Plan-Viewed");
          navigate("/select-plan");
        }
      })
      .catch((error) => {
        setIsCallingAPI(false);

        if (
          error.response?.data.statusCode in
          ["AS005", "AS001", "AS300", "AS301"]
        ) {
          Cookies.set(
            "LoginAttempts",
            JSON.stringify({
              "last-attempt-ts": new Date().toUTCString(),
              "failed-attempts": failedLoginAttempts,
            })
          );
          analyticsContext.track(
            `Web: Login-Attempt-Failed-${failedLoginAttempts}`
          );
        }

        let errorMessage = ErrorCodeMap[error.response?.data.statusCode]
          ? ErrorCodeMap[error.response?.data.statusCode]
          : error.response?.data.statusMessage;
        setLoginError(errorMessage);
        // Logging back-end error for debugging
        console.error(error.response?.data.statusMessage);
      });
  };

  return (
    <>
      <div className="hidden regmd:block">
        <TopNavbar />
      </div>
      <div className="regmd:hidden">
        <TopNavbar
          navType="light"
          showMobileLogo={firebaseFlags.firebaseSSO ? false : true}
        />
      </div>
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] min-h-screen bg-white regmd:hidden" />
      <div className="tru-single-modal-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] hidden opacity-60 regmd:block" />
      <div
        id="test_data_login_page"
        className="relative h-full min-h-[calc(100vh-272px)] regmd:flex regmd:items-center regmd:justify-center regmd:py-44"
      >
        <Helmet>
          <title>TruPlay Games: Christian Games, Videos, and Stories</title>
          <meta
            name="description"
            content="Login to your TruPlay account to access faith-based content and more!"
          />
          <link
            rel="canonical"
            href="https://accounts.truplaygames.com/login"
          />
        </Helmet>
        {typeof setIsEmailLogIn === "function" && firebaseFlags.firebaseSSO ? (
          <img
            alt="Back"
            src={BackCaret}
            loading="eager"
            className="absolute left-6 top-10 z-[1000] h-6 w-6 rotate-90 cursor-pointer opacity-80 hover:opacity-100 sm:top-9 sm:h-8 sm:w-8 regmd:hidden"
            onClick={() => setIsEmailLogIn(false)}
          />
        ) : null}
        <div
          className={
            "relative mx-auto flex min-h-screen max-w-[450px] flex-col gap-4 px-6 pb-0 pt-32" +
            " regmd:min-h-[unset] regmd:w-[515px] regmd:max-w-none regmd:gap-6 regmd:rounded-[2rem]" +
            " regmd:bg-white regmd:px-10 regmd:py-8"
          }
        >
          {typeof setIsEmailLogIn === "function" &&
          firebaseFlags.firebaseSSO ? (
            <img
              alt="Back"
              src={BackCaret}
              loading="eager"
              className="absolute left-8 top-8 z-[101] hidden h-8 w-8 rotate-90 cursor-pointer opacity-80 hover:opacity-100 regmd:!block"
              onClick={() => setIsEmailLogIn(false)}
            />
          ) : null}
          <h4 className="text-center text-3xl text-indigo-500">Log In</h4>
          <p className="m-0 text-center">
            Do you have a TruPlay Games Account?
          </p>
          <div>
            <div className="relative flex w-full flex-col gap-2">
              <div className="relative flex w-full items-center">
                <img
                  className="absolute left-2.5 z-[3] w-6"
                  src={EmailIcon}
                  alt=""
                  loading="eager"
                />
                <input
                  id="login_email"
                  className={
                    "relative flex w-full rounded-lg border-0 bg-cool-gray-200" +
                    " px-2.5 py-4 text-left text-base text-indigo-500 outline-none" +
                    " placeholder:text-cool-gray-900/60 focus:bg-cool-gray-300" +
                    " indent-8 focus:ring-2 focus:ring-cerulean-500 focus:placeholder:text-cool-gray-900/80" +
                    (isEmailError || isEmailError
                      ? " ring-2 ring-raspberry-500"
                      : "") +
                    (isCookiesBlocked ? " cursor-not-allowed opacity-50" : "")
                  }
                  ref={emailInputRef}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Email"
                  type="email"
                  disabled={isCookiesBlocked}
                />
              </div>
              <div className="relative flex w-full items-center">
                <img
                  className="absolute left-2.5 z-[3] w-6"
                  src={PasswordIcon}
                  alt=""
                  loading="eager"
                />
                <input
                  id="login_password"
                  className={
                    "relative flex w-full rounded-lg border-0 bg-cool-gray-200" +
                    " px-2.5 py-4 text-left text-base text-indigo-500 outline-none" +
                    " placeholder:text-cool-gray-900/60 focus:bg-cool-gray-300" +
                    " indent-8 focus:ring-2 focus:ring-cerulean-500 focus:placeholder:text-cool-gray-900/80" +
                    (isPasswordError || isPasswordError
                      ? " ring-2 ring-raspberry-500"
                      : "") +
                    (isCookiesBlocked ? " cursor-not-allowed opacity-50" : "")
                  }
                  ref={passwordInputRef}
                  value={password}
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  autoComplete="off"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (!isPasswordAutofilled && e.target.value) {
                      setIsPasswordAutofilled(true);
                    }
                  }}
                  disabled={isCookiesBlocked}
                />
                <div className="absolute right-1.5 top-4">
                  <div
                    className="flex items-center justify-center"
                    role="button"
                    onClick={togglePasswordVisibility}
                  >
                    {password ? (
                      showPassword ? (
                        <img
                          src={eyeShowIcon}
                          alt="Show Password"
                          loading="lazy"
                          className="w-[22px] cursor-pointer text-cerulean-500"
                        />
                      ) : (
                        <img
                          src={eyeHideIcon}
                          alt="Hide Password"
                          loading="lazy"
                          className="w-[22px] cursor-pointer text-cerulean-500"
                        />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="ml-1 flex justify-end pt-1 text-right text-base font-semibold capitalize no-underline">
              <Link
                to="/forgot-password"
                className="mb-2 text-cerulean-500 hover:underline"
              >
                Forgot?
              </Link>
            </div>
            {loginError && <InlineErrorMessage errorMessage={loginError} />}
            {isCookiesBlocked && (
              <InlineErrorMessage
                errorMessage={
                  "Oh no! You blocked all cookies, so you won't " +
                  "be able to see anything. You must enable cookies " +
                  "in your browser settings to be able to log in. " +
                  "Once you do, give the page a refresh."
                }
              />
            )}
            <div
              className={
                "flex justify-start" +
                (loginError || isCookiesBlocked ? " mt-3" : " mt-1")
              }
            >
              <div className="flex items-center">
                <input
                  id="remember_me_checkbox"
                  type="checkbox"
                  onChange={(e) => setIsRememberMe(e.target.checked)}
                  checked={isRememberMe}
                />
                <label
                  className={
                    "ml-3 text-sm" +
                    (isRememberMe
                      ? " text-cool-gray-900"
                      : " text-cool-gray-900/50")
                  }
                >
                  Remember me
                </label>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 flex w-full flex-col gap-4 px-6 pb-8 regmd:static regmd:p-0">
            <Button
              id="log_in_page_submit"
              loading={isCallingAPI}
              disabled={!isButtonActive}
              onClick={
                firebaseFlags.firebaseAuth
                  ? handleFirebaseLogIn
                  : handleOnSubmit
              }
              btnType="green"
              className="w-full"
            >
              Log In
            </Button>
            <div className="flex items-center justify-center text-center">
              <span className="inline-block p-0.5 text-base font-medium text-cool-gray-900">
                Don't have an account?
              </span>
              <Link to={"/user-registration" + GetContinueToQueryString()}>
                <Button
                  id="log_in_sign_up_cta"
                  btnType="tertiaryAlt"
                  className="ml-2 !text-base hover:underline active:!text-cerulean-500"
                >
                  Sign Up
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
