// Componentes //
import HowItWorks from "../../components/crowdfunding/HowItWorks";
import HowItWorksCTA from "../../components/crowdfunding/HowItWorksCTA";
import SupportTheMission from "../../components/crowdfunding/SupportTheMission";
import WhatMakesUsDifferent from "../../components/crowdfunding/WhatMakesUsDifferent";
import WhyNow from "../../components/crowdfunding/WhyNow";
import WhySupportMatters from "../../components/crowdfunding/WhySupportMatters";
import MoreGames from "../../components/crowdfunding/MoreGames";
import WhereWereGoing from "../../components/crowdfunding/WhereWereGoing";
import OurJourneySoFar from "../../components/crowdfunding/OurJourneySoFar";
import CustomerReviewBreak from "../../components/crowdfunding/CustomerReviewBreak";
import BringingJOJBack from "../../components/crowdfunding/BringingJOJBack";
import SeeTheImpactJOJ from "../../components/crowdfunding/SeeTheImpactJOJ";
import OurMission from "../../components/crowdfunding/OurMission";

// Assets //
import BGLandingImage from "../../global-assets/assets/BG_Image_Landing.webp";

export default function Crowdfunding() {
  return (
    <div
      id="test_crowdfunding_page"
      className="relative min-h-screen overflow-hidden lg:flex-col lg:items-center lg:justify-center"
    >
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_110%_-10%,#68e0cf_0%,transparent_20%)] opacity-40" />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_110%_10%,#38D58A_0%,transparent_10%)] opacity-20" />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3] bg-[radial-gradient(circle_at_70%_15%,#652c80_0%,transparent_20%)] opacity-50" />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_0%_20%,#68e0cf_0%,transparent_15%)] opacity-15" />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_100%_25%,#652c80_0%,transparent_10%)] opacity-40" />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_0%_35%,#38D58A_0%,transparent_10%)] opacity-20" />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_100%_52%,#68e0cf_0%,transparent_20%)] opacity-20" />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3] bg-[radial-gradient(circle_at_0%_62%,#652c80_0%,transparent_20%)] opacity-50" />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_110%_72%,#38D58A_0%,transparent_10%)] opacity-20" />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[2] bg-[radial-gradient(circle_at_0%_85%,#68e0cf_0%,transparent_10%)] opacity-30" />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3] bg-[radial-gradient(circle_at_100%_100%,#652c80_0%,transparent_30%)] opacity-30" />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_50%_100%,#20568B_0%,transparent_20%)] opacity-20" />
      <div className="mx-auto flex max-w-[1440px] flex-col gap-12 px-5 py-24 sm:px-6 sm:pb-16 md:px-7 lg:gap-32 lg:px-12 lg:py-32 2xl:px-6 3xl:px-0">
        <SupportTheMission />
        <WhyNow />
        <OurMission />
        <WhatMakesUsDifferent />
        <OurJourneySoFar />
      </div>
      <CustomerReviewBreak />
      <div className="mx-auto flex max-w-[1440px] flex-col gap-12 px-5 py-24 sm:px-6 sm:pb-16 md:px-7 lg:gap-32 lg:px-12 lg:py-32 2xl:px-6 3xl:px-0">
        <WhereWereGoing />
        <BringingJOJBack />
        <SeeTheImpactJOJ />
        <MoreGames />
        <WhySupportMatters />
        <HowItWorks />
      </div>
      <HowItWorksCTA />
      <div className="absolute bottom-auto left-0 right-0 top-0 -z-[3] mx-auto max-w-none overflow-hidden regmd:flex">
        <img
          src={BGLandingImage}
          loading="eager"
          sizes="100vw"
          alt=""
          className="relative -z-[1] w-full min-w-[1920px]"
        />
      </div>
    </div>
  );
}
