import { useEffect } from "react";

const useKlaviyo = (formId: any, klaviyoPubKey: any) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoPubKey}`;
    script.async = true;
    script.type = "text/javascript";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [klaviyoPubKey]);

  const displayPopup = () => {
    window._klOnsite = window._klOnsite || [];
    window._klOnsite.push(["openForm", formId]);
  };

  return displayPopup;
};

export default useKlaviyo;
