import { useState } from "react";
import AboutValues from "../AboutValues/AboutValues";
import VideoModal from "../../common/VideoModal";
import AboutWhyWeCharge from "../AboutWhyWeCharge/AboutWhyWeCharge";

// Assets //
import AboutVideo from "../../../global-assets/assets/aboutvideo.webp";
import AboutHeroBG from "../../../global-assets/assets/aboutherobg.webp";
import LargePlayBtn from "../../crowdfunding/assets/branded_play_btn.svg";
import ThumbMovement from "../../../global-assets/assets/thumbnail_join_the_movement.webp";

export default function AboutHero() {
  const [isVideoVisible, setIsVideoVisible] = useState<boolean>(false);

  const toggleVideoVisibility = () => {
    setIsVideoVisible(!isVideoVisible);
  };

  return (
    <>
      <div className="tru-about-hero-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-50 sm:opacity-60" />
      <div className="relative flex flex-col justify-end gap-24 overflow-hidden px-5 py-12 pt-24 sm:px-6 md:px-7 lg:gap-40 lg:px-12 lg:pt-32">
        <div className="relative mx-auto flex max-w-[1440px] flex-col items-center justify-between gap-x-8 gap-y-8 md:flex-row">
          <div className="max-w-none flex-1">
            <div className="flex max-w-[820px] flex-col items-center justify-center text-center md:items-start md:justify-start md:text-left">
              <div className="relative mb-4">
                <h1 className="text-transparent bg-gradient_text bg-clip-text">
                  TruPlay's Mission
                </h1>
              </div>
              <div className="max-w-[1040px]">
                <p className="mb-0 text-white">
                  Our mission is to transform generations of children in such a
                  profound way that it will shape culture. At TruPlay, we're
                  building a beautiful, fun, world-class entertainment platform
                  which invites children into a world of hope and God's truth.
                </p>
              </div>
            </div>
          </div>
          <div className="group relative z-[3] min-w-[50%] flex-1 rounded-[40px] bg-[rgb(255,255,255,0.1)] p-5 duration-75 hover:scale-105">
            <div className="w-full overflow-hidden rounded-[30px]">
              <img
                id="our_mission_video_btn"
                role="button"
                src={LargePlayBtn}
                loading="lazy"
                alt=""
                className="absolute -bottom-[50px] left-0 right-0 top-auto z-[5] mx-auto w-[120px] cursor-pointer rounded-full duration-75 group-hover:-translate-y-3 sm:-bottom-[65px] sm:w-[140px] md:-bottom-[80px] md:w-[200px] lg:-bottom-[100px] lg:w-[215px] xl:w-[225px]"
                onClick={() => toggleVideoVisibility()}
              />
              <img src={AboutVideo} loading="eager" alt="" />
            </div>
          </div>
        </div>
        <AboutWhyWeCharge />
        <AboutValues />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3]">
          <div className="tru-about-hero-bg-overlay absolute bottom-0 left-0 right-0 top-0 -z-[3]" />
          <img
            src={AboutHeroBG}
            loading="eager"
            alt=""
            className="relative -z-[5] h-full w-full object-cover"
          />
        </div>
      </div>
      {isVideoVisible && (
        <VideoModal
          setIsOpen={setIsVideoVisible}
          videoLink="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/join_the_movement%20(720p).mp4"
          shareLink="https://vimeo.com/911651950"
          thumbnail={ThumbMovement}
        />
      )}
    </>
  );
}
