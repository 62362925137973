import { useEffect } from "react";
import { Helmet } from "react-helmet";
import AboutTeam from "../../components/about/AboutTeam/AboutTeam";
import AboutAdvisory from "../../components/about/AboutAdvisory/AboutAdvisory";
import AboutHero from "../../components/about/AboutHero/AboutHero";
import AboutPrayerPartner from "../../components/about/AboutPrayerPartner/AboutPrayerPartner";

const AboutWhoWeAre = () => {
  useEffect(() => {
    const cookiesAvailable = () => {
      try {
        localStorage.setItem("test", "test");
        localStorage.removeItem("test");
        return true;
      } catch (e) {
        return false;
      }
    };

    if (cookiesAvailable() && process.env.REACT_APP_ENVIRONMENT === "prod") {
      const script = document.createElement("script");
      script.src =
        "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=STmu7s";
      script.async = true;
      script.type = "text/javascript";
      document.body.appendChild(script);

      return () => {
        if (document.body.contains(script)) {
          document.body.removeChild(script);
        }
      };
    }
  }, []);

  return (
    <div id="test_data_about_page" className="min-h-screen">
      <Helmet>
        <title>About Us | TruPlay Games</title>
        <meta
          name="description"
          content="Welcome to TruPlay, where we merge faith with fun to create a world-class entertainment platform that nurtures hope and biblical truth for kids."
        />
        <link rel="canonical" href="https://www.truplaygames.com/about" />
      </Helmet>
      <AboutHero />
      <AboutTeam />
      <AboutAdvisory />
      <AboutPrayerPartner />
    </div>
  );
};

export default AboutWhoWeAre;
