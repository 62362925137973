import { useRef, useEffect, useState, useContext } from "react";

// Contexts //
import Utm from "../../components/analytics/UTM";
import { MixPanelAnalyticsContext } from "../../components/analytics/Analytics";
import TagManager from "react-gtm-module";
import { DeviceSessionContext } from "../../components/analytics/DeviceContext";
import {
  Context,
  GetOrCreateSessionAuthContext,
} from "../../config/UserAuthContext";
import Cookies from "js-cookie";

// Assets //
import pf_img2 from "../../global-assets/assets/pf_img2.svg";
import pf_img3 from "../../global-assets/assets/pf_img3.svg";
import pf_img4 from "../../global-assets/assets/pf_img4.svg";
import pf_img5 from "../../global-assets/assets/pf_img5_1.svg";
import pf_img6 from "../../global-assets/assets/pf_img6.svg";
import pf_img7 from "../../global-assets/assets/pf_img7_1.svg";
import pf_img8 from "../../global-assets/assets/pf_img8.svg";
import pf_img9 from "../../global-assets/assets/pf_img9.svg";
import pf_img10 from "../../global-assets/assets/pf_img10.svg";
import pf_img1 from "../../global-assets/assets/pf_img1.svg";
import GetStartedImage from "../../global-assets/assets/get_started_image_ben.png";
import AccentStars1 from "../../global-assets/assets/Accent_Stars_01.svg";
import AccentStars2 from "../../global-assets/assets/Accent_Stars_02.svg";
import deanCain from "../../global-assets/assets/dean-cain-headshot.png";
import kevinSorbo from "../../global-assets/assets/kevin-sorbo-headshot.png";
import candaceBure from "../../global-assets/assets/candace-cameron-burre-headshot.png";
import BGLandingImage from "../../global-assets/assets/BG_Image_Landing.webp";
import kidsBeanBags from "../../global-assets/assets/kids-on-bean-bags.png";
import ReviewStar from "../../global-assets/assets/star-tangerine.svg";
import ThumbCommercial from "../../global-assets/assets/thumbnail_its_here.webp";
import ThumbDeanCain from "../../global-assets/assets/thumbnail_dean_cain.webp";

// Components //
import InlineUserRegistration from "../../components/welcome/InlineUserRegistration/InlineUserRegistration";
import AppReviewSlider from "../../components/common/AppReviewsSlider";
import FAQSection from "../../components/common/FAQSection/FAQSection";
import TrialNavBar from "../../components/trial/TrialNavBar";
import TrialFooter from "../../components/trial/TrialFooter";
import RecommendedBy from "../../components/common/RecommendedBy";
import Button from "../../components/common/Button";
import TextWithVideoSection from "../../components/common/TextWithVideoSection";
import SectionHeader from "../../components/common/SectionHeader";
import FeaturesIcons from "../../components/common/FeaturesIcons";

/**
 * Login Page to authenticate users with valid email address on file
 *
 * @param props
 * @constructor
 */
interface LoginProps {
  setAuth: any;
  setLoginData: any;
}

const featuresIcons = [
  {
    icon: pf_img2,
    title: "Multiple Games",
  },
  {
    icon: pf_img1,
    title: "Biblical Content & God's Truth",
  },
  {
    icon: pf_img5,
    title: "No Chat",
  },
  {
    icon: pf_img4,
    title: "Create Multiple Profiles",
  },
  {
    icon: pf_img3,
    title: "No Ads or In-App Purchases",
  },
  {
    icon: pf_img7,
    title: "Games & Content Added Frequently",
  },
  {
    icon: pf_img9,
    title: "Digital Comics",
  },
  {
    icon: pf_img10,
    title: "Videos",
  },
  {
    icon: pf_img8,
    title: "Play Off-Line",
  },
  {
    icon: pf_img6,
    title: "Multi-Platform",
  },
];

const TrialLanding = ({ setAuth, setLoginData }: LoginProps) => {
  const userRegRef = useRef<any>(null);
  const mixPanelAnalytics = useContext(MixPanelAnalyticsContext);
  const initialUserContext = GetOrCreateSessionAuthContext();
  const [deviceCtx] = useState<DeviceSessionContext>(
    new DeviceSessionContext()
  );
  const [userContext] = useState(initialUserContext);
  const GlobalUserId = Cookies.get("GlobalUserId");
  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);

  Cookies.remove(Context.Pk);

  const celebDeanCain = {
    image: deanCain,
    name: "Dean Cain",
    quote: `"TruPlay is a team who are fully committed to making
    high quality, engaging entertainment for today's kids.
    They've developed games that will attract and retain
    their attention. Games with positive messages that
    reinforce good values."`,
  };

  const celebCandaceBure = {
    image: candaceBure,
    name: "Candace Cameron Burre",
    quote: `“If you're a parent or someone with young kids in your 
    life, you want your kids to be safe online. With TruPlay, kids 
    have fun in a world of safe and original content.”`,
  };

  const celebKevinSorbo = {
    image: kevinSorbo,
    name: "Kevin Sorbo",
    quote: `“This is more than just an entertainment platform - it's 
    a sanctuary of hope and faith, a place where families can strengthen 
    their bonds through God's word. I love what TruPlay stands for and 
    the positive impact it offers to its users.”`,
  };

  const handleScrollToAccountCreation = () => {
    if (userRegRef.current) {
      const targetScrollPosition =
        userRegRef.current.getBoundingClientRect().top + window.scrollY - 120;
      window.scrollTo({
        top: targetScrollPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    Cookies.set(Context.FreeTrial, "true");
    if (queryParams.get("via") === "subsplash") {
      Cookies.set(Context.Subsplash, "true");
    }

    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        page_name: "marketing: Trial Landing",
        page_type: "marketing",
        os_type: deviceCtx.os.name,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    let utm = new Utm(mixPanelAnalytics);
    utm.track("Web: Trial-Page-Viewed", deviceCtx);
  }, []);

  const contentTextHero = (
    <div className="flex w-full flex-col items-center justify-center gap-6">
      <p className="relative m-0 overflow-hidden text-center text-lg font-normal text-white lg:!line-clamp-none lg:text-left lg:text-xl lg:font-semibold">
        TruPlay is the answer for parents and care-takers in search of safe,
        productive, character and faith-building screen time alternatives for
        their children.
      </p>
      <Button
        id="trial_hero_cta"
        onClick={handleScrollToAccountCreation}
        btnType="green"
        className="w-full"
      >
        Redeem Your Free Week
      </Button>
    </div>
  );
  const fiveStarReview = (
    <div className="flex items-center justify-center lg:justify-start">
      <div className="mr-3 flex gap-1">
        {Array(5)
          .fill(0)
          .map(() => (
            <img
              alt="..."
              src={ReviewStar}
              key={Math.random()}
              className="max-w-8"
            />
          ))}
      </div>
      <p className="mb-0 text-xs text-white sm:text-sm">
        100s of Verified 5-star Reviews
      </p>
    </div>
  );

  const headerHero = (
    <>
      {fiveStarReview}
      <h2 className="text-transparent relative bg-gradient_text bg-clip-text font-bold">
        Turn Game Time into God Time
        <img
          src={AccentStars2}
          alt="Accent Stars"
          loading="eager"
          className="absolute -right-16 -top-8 hidden lg:block"
        />
      </h2>
    </>
  );

  const contentTextDean = (
    <div className="flex w-full flex-col items-center justify-center gap-6">
      <p className="relative m-0 overflow-hidden text-center text-lg font-normal text-white lg:!line-clamp-none lg:text-left lg:text-xl lg:font-semibold">
        Kids today get an average of 7 hours of screen time per day. TruPlay
        meets kids where they are and has a vital impact where it matters most.
      </p>
      <Button
        id="trial_game_changer_cta"
        onClick={handleScrollToAccountCreation}
        btnType="green"
        className="w-full"
      >
        Redeem Your Free Week
      </Button>
    </div>
  );

  const headerDean = (
    <h2 className="text-transparent relative bg-gradient_text bg-clip-text font-bold">
      It's Time for a Game-Changer
      <img
        src={AccentStars1}
        alt="Accent Stars"
        loading="eager"
        className="absolute -left-16 -top-8 hidden lg:block"
      />
    </h2>
  );

  return (
    <>
      <link href="https://fonts.googleapis.com" rel="preconnect" />
      <link
        href="https://fonts.gstatic.com"
        rel="preconnect"
        crossOrigin="anonymous"
      />
      <TrialNavBar
        handleScrollToAccountCreation={handleScrollToAccountCreation}
      />
      <div id="test_data_squeeze_page">
        <div className="relative overflow-hidden">
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_0%_50%,#652c80_0%,#00203f_40%,transparent_100%)] opacity-40" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_100%_100%,#38d58a_0%,#00203f_40%,transparent_100%)] opacity-25" />
          <div className="mx-auto flex max-w-[1440px] flex-col gap-8 px-5 pb-16 pt-28 sm:px-6 md:gap-12 md:px-7 lg:gap-28 lg:pb-28 lg:pt-40">
            <div className="flex flex-col gap-4 lg:hidden">
              {fiveStarReview}
              <SectionHeader
                headerType="h2"
                headerText="Turn Game Time into God Time"
              />
            </div>
            <TextWithVideoSection
              headerText={headerHero}
              hideHeaderOnMobile
              contentText={contentTextHero}
              contentTextColor="text-white"
              videoUrl="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/its_here%20(720p).mp4"
              videoId="872592906"
              videoThumbnail={ThumbCommercial}
              videoShareUrl="https://vimeo.com/872592906"
              videoPosition="left"
              videoSectionTestId="trial_commercial_video"
              textPositionOnMobile="below"
            />
            <div className="flex flex-col gap-8 lg:flex-row lg:gap-16">
              <div className="flex flex-[0.75_1_0] flex-col justify-center gap-12">
                <div className="flex justify-center">
                  <h2 className="text-transparent relative bg-gradient_text bg-clip-text text-center sm:max-w-[400px] md:max-w-[500px] lg:max-w-none lg:text-left">
                    <img
                      src={AccentStars1}
                      loading="lazy"
                      alt=""
                      className="absolute -left-[63px] -top-[15px] hidden lg:inline"
                    />
                    Content & Features that Bring you Peace of Mind
                  </h2>
                </div>
                <Button
                  id="trial_peace_of_mind_cta"
                  onClick={handleScrollToAccountCreation}
                  btnType="green"
                  className="hidden w-full lg:inline"
                >
                  Redeem Your Free Week
                </Button>
              </div>
              <FeaturesIcons icons={featuresIcons} />
            </div>
          </div>
          <div className="absolute bottom-auto left-0 right-0 top-0 -z-[3] mx-auto max-w-none overflow-hidden regmd:flex">
            <img
              src={BGLandingImage}
              loading="eager"
              sizes="100vw"
              alt=""
              className="relative -z-[1] w-full min-w-[1920px]"
            />
          </div>
        </div>
        <div className="relative overflow-hidden bg-white/[6%] shadow-[0px_0px_80px_0px_#001b36]">
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_30%_50%,#652c80_20%,#00203f_60%,transparent_100%),radial-gradient(circle_at_100%_40%,#38d58a_0%,#00203f_50%,transparent_100%)] opacity-30" />
          <div ref={userRegRef} />
          <div className="mx-auto flex max-w-[1440px] flex-col px-5 pb-12 pt-8 sm:px-6 md:px-7">
            <InlineUserRegistration
              setAuth={setAuth}
              setLoginData={setLoginData}
              pageDetails={{
                pageName: "Trial Landing",
                pageType: "Marketing",
              }}
              image={GetStartedImage}
              header="Get Started"
              checkmarkPoints={[
                "Positive values organically integrated into gameplay",
                "One app, multiple high-quality Christian games",
                "Peace of mind: no ads, no chat rooms, no in-app purchases",
                "Exciting new games and content added every month",
              ]}
              ctaText="Redeem Your Free Week"
            />
          </div>
        </div>
        <div className="relative overflow-hidden">
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_0%_0%,#68e0cf_0%,#00203f_30%,transparent_100%)] opacity-20" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_100%_50%,#652c80_0%,#00203f_40%,transparent_100%)] opacity-40" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_0%_100%,#38d58a_0%,#00203f_40%,transparent_100%)] opacity-25" />
          <div className="mx-auto flex max-w-[1440px] flex-col gap-8 px-5 py-16 sm:px-6 md:gap-12 md:px-7 lg:gap-28 lg:py-28">
            <SectionHeader
              headerType="h2"
              headerText="It's Time for a Game-Changer"
            />
            <TextWithVideoSection
              headerText={headerDean}
              hideHeaderOnMobile
              contentText={contentTextDean}
              contentTextColor="text-white"
              videoUrl="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/dean_cain%20(720p).mp4"
              videoId="872592906"
              videoThumbnail={ThumbDeanCain}
              videoShareUrl="https://vimeo.com/872592906"
              videoPosition="right"
              videoSectionTestId="trial_dean_cain_video"
              textPositionOnMobile="below"
            />
          </div>
          <div className="-mx-[20px] flex flex-col gap-16 sm:-mx-[25px] md:-mx-[30px] lg:-mx-[50px]">
            <AppReviewSlider lightBackground />
          </div>
        </div>
        <div className="flex flex-col justify-center bg-white/[6%] shadow-[0_0_80px_0_#001b36]">
          <div className="relative overflow-hidden">
            <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_0%_0%,#38d58a_0%,#00203f_40%,transparent_100%)] opacity-25" />
            <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_100%_100%,#68e0cf_0%,#00203f_30%,transparent_100%)] opacity-20" />
            <div className="mx-auto flex max-w-[1440px] flex-col gap-8 px-5 py-16 sm:px-6 md:gap-12 md:px-7 lg:py-28">
              <SectionHeader
                headerType="h2"
                headerText="We Set the Bar High for Quality"
              />
              <div className="flex flex-col gap-8 lg:flex-row lg:gap-16">
                <div className="flex flex-[0.7_1_0] items-center justify-center">
                  <img
                    src={kidsBeanBags}
                    loading="lazy"
                    alt=""
                    className="rounded-[40px]"
                  />
                </div>
                <div className="flex flex-1 flex-col justify-between gap-8">
                  <div>
                    <h3 className="text-transparent bg-gradient_text bg-clip-text">
                      Kid Tested
                    </h3>
                    <p className="m-0 font-bold text-white">
                      We host regular, kid-led focus groups for every game and
                      feature launch. We are rigorous, we are honest. We listen.
                    </p>
                  </div>
                  <div>
                    <h3 className="text-transparent bg-gradient_text bg-clip-text">
                      Top Tier Talent
                    </h3>
                    <p className="m-0 font-bold text-white">
                      TruPlay recruits world-class game designers and executives
                      with rich experience from companies such as Electronic
                      Arts, Zynga, Warner Bros. and Jam City.
                    </p>
                  </div>
                  <div>
                    <h3 className="text-transparent bg-gradient_text bg-clip-text">
                      Elevating Christian Entertainment
                    </h3>
                    <p className="m-0 font-bold text-white">
                      We are on a mission to deliver thrilling content that
                      doesn't compromise on production value or Christian
                      values.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative overflow-hidden">
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_100%_0%,#68e0cf_0%,#00203f_30%,transparent_100%)] opacity-20" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_0%_40%,#652c80_0%,#00203f_40%,transparent_100%)] opacity-40" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_100%_60%,#38d58a_0%,#00203f_40%,transparent_100%)] opacity-25" />
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_0%_100%,#1f9cff_0%,#00203f_50%,transparent_100%)] opacity-20" />
          <div className="mx-auto flex max-w-[1440px] flex-col items-center justify-center gap-8 px-5 py-16 sm:px-6 md:gap-12 md:px-7 lg:gap-28 lg:py-28">
            <RecommendedBy
              person1={celebDeanCain}
              person2={celebCandaceBure}
              person3={celebKevinSorbo}
            />
            <FAQSection />
          </div>
        </div>
      </div>
      <TrialFooter
        handleScrollToAccountCreation={handleScrollToAccountCreation}
      />
    </>
  );
};

export default TrialLanding;
