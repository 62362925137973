import React from "react";
import { Link } from "react-router-dom";
import TruPlayLogo from "../../global-assets/assets/truplay-logo-large.png";
import {
  GetOrCreateSessionAuthContext,
  isAuthenticatedWithValidToken,
} from "../../config/UserAuthContext";
import Cookies from "js-cookie";
import TagManager from "react-gtm-module";
import Button from "../common/Button";

interface TrialFooterProps {
  handleScrollToAccountCreation: () => void;
}

const TrialFooter = ({ handleScrollToAccountCreation }: TrialFooterProps) => {
  const osType = navigator.userAgent;
  const userContext = GetOrCreateSessionAuthContext();
  const isAuthenticated = isAuthenticatedWithValidToken();
  const GlobalUserId = Cookies.get("GlobalUserId");

  const handleGtagClick =
    (linkURL: string) =>
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      const linkName = e.currentTarget.innerText;

      TagManager.dataLayer({
        dataLayer: {
          event: "select_content",
          content_type: "menu",
          os_type: osType,
          link_name: linkName,
          link_url: linkURL,
          plan_type: userContext.subscription.sources[0]?.productId ?? "none",
          custom_user_id: GlobalUserId || undefined,
        },
      });
    };

  return (
    <div className="bg-indigo-900 px-5 py-12 sm:px-6 md:px-7 lg:max-h-[272px] lg:px-12 lg:py-14 lg:pb-20">
      <div className="mx-auto flex max-w-[1440px] flex-col items-center justify-between gap-10 lg:flex-row">
        <div className="flex h-full min-w-[240px] flex-col items-center justify-center bg-indigo-900 lg:items-baseline">
          <div className="mb-3 max-w-[150px] sm:max-w-[193px]">
            <img src={TruPlayLogo} loading="lazy" alt="" />
          </div>
          <div className="mb-5 hidden text-center font-display text-sm font-medium text-white lg:mb-8 lg:inline lg:text-left">
            <div>
              © TruPlay. All Rights Reserved.
              <br />
              <Link
                id="privacy_policy_link"
                to="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer text-white underline"
                onClick={(e) => handleGtagClick("/privacy-policy")}
              >
                Privacy Policy
              </Link>
              .{" "}
              <Link
                id="terms_and_conditions_link"
                to="/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer text-white underline"
                onClick={(e) => handleGtagClick("/terms-and-conditions")}
              >
                Terms &amp; Conditions
              </Link>
              .
            </div>
          </div>
          <div className="hidden text-center font-display text-xs font-medium text-white lg:inline lg:text-left">
            <div>Content and platform availability may vary by region.</div>
          </div>
        </div>
        {!isAuthenticated && (
          <div className="flex h-full min-w-[240px] flex-col items-center justify-center bg-indigo-900 lg:items-baseline">
            <Button
              id="trial_footer_cta_btn"
              onClick={handleScrollToAccountCreation}
              btnType="green"
              className="self-center !px-10 lg:self-end"
            >
              Redeem Your Free Week
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrialFooter;
