interface IStep {
  [key: string]: string;
}

interface IThreeStepsSectionProps {
  steps: IStep[];
}

export default function ThreeStepsSection({ steps }: IThreeStepsSectionProps) {
  return (
    <div className="flex flex-col gap-10 lg:flex-row lg:gap-12">
      {steps.map((step, index) => (
        <div
          key={step.title}
          className="flex flex-1 flex-col gap-1 px-8 lg:px-8"
        >
          <div className="flex gap-6">
            <div className="text-transparent bg-gradient_text bg-clip-text font-display text-9xl font-bold !leading-none lg:text-[9rem]">
              {index + 1}
            </div>
            <div className="flex items-end">
              <h3 className="mb-4 text-2xl text-white">{step.title}</h3>
            </div>
          </div>
          <p className="m-0 text-xl font-normal text-indigo-200">{step.body}</p>
        </div>
      ))}
    </div>
  );
}
