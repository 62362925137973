import { useState } from "react";

// Components //
import Video from "../../components/common/Video";
import Button from "../../components/common/Button";

// Asseets //
import LargePlayBtn from "../crowdfunding/assets/branded_play_btn.svg";
import BlueCaretDown from "../../global-assets/assets/icon-caret-down-cerulean.svg";
import AccentStars1 from "../../global-assets/assets/Accent_Stars_01.svg";
import AccentStars2 from "../../global-assets/assets/Accent_Stars_02.svg";

interface TextWithVideoSectionProps {
  headerText: string | JSX.Element;
  contentText: string | JSX.Element;
  hideHeaderOnMobile?: boolean;
  videoUrl: string;
  videoId?: number | string;
  videoThumbnail: string;
  videoShareUrl: string;
  videoPosition?: "left" | "right";
  videoSectionTestId: string;
  handleToggleReadMore?: boolean;
  textPositionOnMobile?: "above" | "below";
  showAccentStars?: boolean;
  headerTextColor?: string; // Prop for header text color
  contentTextColor?: string; // Prop for content text color
  headerType?: string;
  showCustomPlayButton?: boolean;
}

const TextWithVideoSection: React.FC<TextWithVideoSectionProps> = ({
  headerText,
  contentText,
  hideHeaderOnMobile = false,
  videoUrl,
  videoId,
  videoThumbnail,
  videoShareUrl,
  videoPosition = "right",
  videoSectionTestId = "video_section",
  handleToggleReadMore = false,
  textPositionOnMobile = "below",
  showAccentStars = false,
  headerTextColor = "text-white",
  contentTextColor = "text-indigo-200",
  headerType = "h3",
  showCustomPlayButton = true,
}) => {
  const [showMore, setShowMore] = useState(false);
  const isVideoOnLeft = videoPosition === "left";
  const handleToggle = () => {
    setShowMore(!showMore);
  };

  const handleHeaderTag = (children: JSX.Element) => {
    if (headerType === "h1") {
      return (
        <h1
          className={`relative text-4xl font-bold lg:text-5xl ${headerTextColor}`}
        >
          {children}
        </h1>
      );
    }
    if (headerType === "h2") {
      return (
        <h2
          className={`relative text-3xl font-bold lg:text-4xl ${headerTextColor}`}
        >
          {children}
        </h2>
      );
    }
    if (headerType === "h3") {
      return (
        <h3
          className={`relative text-2xl font-bold lg:text-3xl ${headerTextColor}`}
        >
          {children}
        </h3>
      );
    }
  };

  return (
    <div
      className={`flex flex-col items-center justify-center gap-6 lg:flex-row lg:gap-12 ${isVideoOnLeft ? "lg:flex-row-reverse" : ""}`}
    >
      {textPositionOnMobile === "above" && (
        <div className="order-1 flex flex-col gap-2 text-center lg:hidden">
          {!hideHeaderOnMobile &&
            typeof headerText === "string" &&
            handleHeaderTag(
              <>
                {!isVideoOnLeft && (
                  <img
                    src={AccentStars1}
                    alt="Accent Stars"
                    loading="eager"
                    className={
                      "absolute -left-16 -top-8 hidden lg:block" +
                      (!showAccentStars ? " !hidden" : "")
                    }
                  />
                )}
                {headerText}
                {isVideoOnLeft && (
                  <img
                    src={AccentStars2}
                    alt="Accent Stars"
                    loading="eager"
                    className={
                      "absolute -right-16 -top-8 hidden lg:block" +
                      (!showAccentStars ? " !hidden" : "")
                    }
                  />
                )}
              </>
            )}
          {!hideHeaderOnMobile && typeof headerText !== "string" && headerText}
          {typeof contentText === "string" ? (
            <>
              <p
                className={`m-0 text-lg font-normal lg:text-xl lg:font-semibold ${contentTextColor} 
                ${!handleToggleReadMore ? "!line-clamp-none" : ""} 
                ${showMore ? "line-clamp-none" : "line-clamp-6"}`}
              >
                {contentText}
              </p>
              {handleToggleReadMore && (
                <Button
                  btnType="tertiaryAlt"
                  onClick={handleToggle}
                  rightIcon={
                    <img
                      src={BlueCaretDown}
                      alt="Caret"
                      loading="lazy"
                      className={`ml-4 h-6 w-6 duration-300 ${!showMore ? "rotate-180" : ""}`}
                    />
                  }
                  className="self-center md:hidden"
                >
                  {!showMore ? "Read More" : "Read Less"}
                </Button>
              )}
            </>
          ) : (
            contentText
          )}
        </div>
      )}

      <div className="order-2 w-full lg:w-2/3">
        <div className="relative w-full">
          <Video
            id={`${videoSectionTestId}_section_id`}
            videoId={videoId}
            url={videoUrl}
            thumbnail={videoThumbnail}
            shareSmall
            shareUrl={videoShareUrl}
            playsInline
            disableRemotePlayback
            customPlayBtn={showCustomPlayButton ? LargePlayBtn : null}
          />
        </div>
      </div>

      {textPositionOnMobile === "below" && (
        <div className="order-3 flex flex-col gap-2 pt-4 text-center lg:hidden">
          {!hideHeaderOnMobile &&
            typeof headerText === "string" &&
            handleHeaderTag(
              <>
                {!isVideoOnLeft && (
                  <img
                    src={AccentStars1}
                    alt="Accent Stars"
                    loading="eager"
                    className={
                      "absolute -left-16 -top-8 hidden lg:block" +
                      (!showAccentStars ? " !hidden" : "")
                    }
                  />
                )}
                {headerText}
                {isVideoOnLeft && (
                  <img
                    src={AccentStars2}
                    alt="Accent Stars"
                    loading="eager"
                    className={
                      "absolute -right-16 -top-8 hidden lg:block" +
                      (!showAccentStars ? " !hidden" : "")
                    }
                  />
                )}
              </>
            )}
          {!hideHeaderOnMobile && typeof headerText !== "string" && headerText}
          {typeof contentText === "string" ? (
            <>
              <p
                className={`m-0 text-lg font-normal lg:text-xl lg:font-semibold ${contentTextColor} 
                ${!handleToggleReadMore ? "!line-clamp-none" : ""} 
                ${showMore ? "line-clamp-none" : "line-clamp-6"}`}
              >
                {contentText}
              </p>
              {handleToggleReadMore && (
                <Button
                  btnType="tertiaryAlt"
                  onClick={handleToggle}
                  rightIcon={
                    <img
                      src={BlueCaretDown}
                      alt="Caret"
                      loading="lazy"
                      className={`ml-4 h-6 w-6 duration-300 ${!showMore ? "rotate-180" : ""}`}
                    />
                  }
                  className="self-center md:hidden"
                >
                  {!showMore ? "Read More" : "Read Less"}
                </Button>
              )}
            </>
          ) : (
            contentText
          )}
        </div>
      )}

      <div className="order-1 hidden flex-col items-start justify-center gap-4 lg:flex lg:w-1/3">
        {typeof headerText === "string" &&
          handleHeaderTag(
            <>
              {!isVideoOnLeft && (
                <img
                  src={AccentStars1}
                  alt="Accent Stars"
                  loading="eager"
                  className={
                    "absolute -left-16 -top-8 hidden lg:block" +
                    (!showAccentStars ? " !hidden" : "")
                  }
                />
              )}
              {headerText}
              {isVideoOnLeft && (
                <img
                  src={AccentStars2}
                  alt="Accent Stars"
                  loading="eager"
                  className={
                    "absolute -right-16 -top-8 hidden lg:block" +
                    (!showAccentStars ? " !hidden" : "")
                  }
                />
              )}
            </>
          )}
        {typeof headerText !== "string" && headerText}
        {typeof contentText === "string" ? (
          <p
            className={`m-0 text-lg font-normal lg:text-xl lg:font-semibold ${contentTextColor}`}
          >
            {contentText}
          </p>
        ) : (
          contentText
        )}
      </div>
    </div>
  );
};

export default TextWithVideoSection;
