interface IIcon {
  icon: string;
  title: string;
}

interface IFeaturesIconsProps {
  icons: IIcon[];
}

export default function FeaturesIcons({ icons }: IFeaturesIconsProps) {
  return (
    <div className="flex w-full flex-1 grid-cols-1 flex-wrap justify-center gap-x-4 gap-y-4 sm:grid-cols-2 md:grid-cols-3 lg:gap-x-8 lg:gap-y-8 lg:rounded-[40px] lg:bg-white/[6%] lg:p-8 lg:shadow-[0px_0px_80px_0px_#001b36]">
      {icons.map((feature) => (
        <div
          className="flex w-[45%] items-center justify-center rounded-[30px] bg-white/[6%] p-4 shadow-[0px_0px_80px_0px_#001b36] sm:w-[45%] md:w-[30%] md:last:hidden lg:min-h-[155px] lg:w-[30%] lg:bg-white/0 lg:p-0 lg:shadow-none"
          key={feature.title}
        >
          <div
            className="flex flex-col items-center justify-start text-center"
            key={feature.icon}
          >
            <div className="flex w-1/2 min-w-[80px] max-w-[140px] items-center justify-center md:w-[70%] lg:min-w-[110px]">
              <img src={feature.icon} loading="lazy" alt="" />
            </div>
            <h5 className="font-body text-base font-bold text-white lg:text-lg">
              {feature.title}
            </h5>
          </div>
        </div>
      ))}
    </div>
  );
}
