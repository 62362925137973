import AccentStars1 from "../../global-assets/assets/Accent_Stars_01.svg";
import AccentStars2 from "../../global-assets/assets/Accent_Stars_02.svg";

interface ISectionHeader {
  headerType: string;
  headerText: string;
  subtext?: string;
  textColor?: string;
  starLocation?: string;
  textLineNumber?: number;
}

export default function SectionHeader({
  headerType,
  headerText,
  subtext,
  textColor = "gradient", // If it's not a gradient, use a Tailwind Class
  starLocation,
  textLineNumber = 1,
}: ISectionHeader) {
  const handleColor = () => {
    if (textColor === "gradient") {
      return " text-transparent bg-gradient_text bg-clip-text";
    }
    return " " + textColor;
  };

  return (
    <div className="mx-auto flex flex-col items-center justify-center gap-4 lg:gap-6">
      <div
        className={
          "relative" +
          (textLineNumber > 1 ? " max-w-fit lg:max-w-[70%]" : " max-w-fit")
        }
      >
        {headerType === "h1" && (
          <h1
            className={
              "text-center text-4xl !leading-tight lg:text-5xl" + handleColor()
            }
          >
            {headerText}
          </h1>
        )}
        {headerType === "h2" && (
          <h2
            className={
              "text-center text-4xl !leading-tight lg:text-5xl" + handleColor()
            }
          >
            {headerText}
          </h2>
        )}
        {headerType === "h3" && (
          <h2
            className={
              "text-center text-2xl !leading-tight md:text-3xl lg:text-4xl" +
              handleColor()
            }
          >
            {headerText}
          </h2>
        )}
        {starLocation === "left" && (
          <img
            className="absolute -left-[70px] -top-[40px] hidden w-[70px] lg:inline"
            src={AccentStars1}
            alt="Stars"
            loading="eager"
          />
        )}
        {starLocation === "right" && (
          <img
            className="absolute -right-[90px] -top-[40px] hidden h-[70px] lg:inline"
            src={AccentStars2}
            alt="Stars"
            loading="eager"
          />
        )}
      </div>
      {subtext && (
        <p className="m-0 text-center text-lg text-white lg:text-3xl lg:!leading-normal">
          {subtext}
        </p>
      )}
    </div>
  );
}
