import { useState, useEffect } from "react";
import TruPlayLogo from "../../global-assets/assets/truplay-logo-large.png";
import { navigateTo } from "../../config/UserAuthContext";
import { useFeatureFlags } from "../../config/FeatureFlagContext";
import Button from "../common/Button";

interface TrialAltNavBarProps {
  scrollTo?: any;
}

const TrialAltNavBar = ({ scrollTo }: TrialAltNavBarProps) => {
  const featureFlags = useFeatureFlags();
  const FreeTrial30 = featureFlags["2024-04 - 30 Day Free Trial"]?.variationId;
  const [freeTrial30, setFreeTrial30] = useState(false);

  useEffect(() => {
    if (FreeTrial30) {
      switch (FreeTrial30) {
        case "1":
          break;
        case "2":
          setFreeTrial30(true);
          break;
      }
    }
  }, [FreeTrial30]);

  return (
    <>
      <link href="https://fonts.googleapis.com" rel="preconnect" />
      <link
        href="https://fonts.gstatic.com"
        rel="preconnect"
        crossOrigin="anonymous"
      />
      <div className="fixed bottom-auto left-0 right-0 top-0 z-[999] bg-global_nav_bg">
        <div className="relative mx-auto flex h-[104px] max-w-[1536px] items-center justify-between p-6 md:h-[114px]">
          <div className="flex items-center">
            <img
              src={TruPlayLogo}
              alt="Truplay-logo"
              className="w-[7.5rem] sm:w-[9.5rem] lg:w-48"
              id="unauth_tru_logo"
            />
          </div>
          <div className="flex items-center gap-6 font-display text-xl font-bold">
            {freeTrial30 ? (
              <Button
                id="nav_trial_try_btn"
                onClick={
                  scrollTo
                    ? () => scrollTo()
                    : () => navigateTo("/user-registration")
                }
                btnType="green"
                className="sm:px-10"
              >
                {scrollTo ? (
                  <>
                    <div className="hidden lg:inline">Start 30 Day&nbsp;</div>
                    Free Trial
                  </>
                ) : (
                  <div className="flex items-center">
                    Start 30 Day Free Trial
                  </div>
                )}
              </Button>
            ) : (
              <Button
                id="nav_trial_try_btn"
                onClick={
                  scrollTo
                    ? () => scrollTo()
                    : () => navigateTo("/user-registration")
                }
                btnType="green"
                className="sm:px-10"
              >
                {scrollTo ? (
                  <div>
                    <span className="hidden lg:inline">
                      Redeem Your Free Week
                    </span>
                    <span className="lg:hidden">Free Trial</span>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <span className="hidden lg:inline">
                      Redeem Your Free Week
                    </span>
                    <span className="lg:hidden">Free Trial</span>
                  </div>
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrialAltNavBar;
