import SectionHeader from "../common/SectionHeader";
import ThreeBlocksSection from "../common/ThreeBlocksSection";

const blocks = [
  {
    title: "The RhymVerse",
    body: "One app: a whole universe of high-quality, engaging entertainment.",
  },
  {
    title: "Safe & Secure",
    body: "No ads. No chat rooms. No in-app purchases. No WORRIES!",
  },
  {
    title: "Build Character & Nurture Faith",
    body: "Designed to promote positive Christian values.",
  },
];

export default function WhatMakesUsDifferent() {
  return (
    <div className="flex flex-col gap-6 lg:gap-20">
      <SectionHeader
        headerType="h2"
        headerText="What Makes TruPlay Different?"
        starLocation="left"
      />
      <ThreeBlocksSection blocks={blocks} />
    </div>
  );
}
