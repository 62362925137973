import curvewhite from "../global-assets/assets/curvewhite.svg";
import curveblue from "../global-assets/assets/curveblue.svg";
import teambg from "../global-assets/assets/teambg.webp";
import Icon1 from "../global-assets/assets/Signup.webp";
import Icon2 from "../global-assets/assets/Recommend.webp";
import Icon3 from "../global-assets/assets/Earn.webp";
import contactherobg from "../global-assets/assets/contactherobg.webp";
import accent_stars_1 from "../global-assets/assets/Accent_Stars_01.svg";

// TODO: Leaving this here for now. When we have to do another Holiday promo,
// we can come back to this. We are not currently using the HomeSlider component
export const HolidayHomeslide = [
  {
    id: 1,
    title: "Experience faith-filled fun this Christmas",
    description:
      "Enter an animated world of fun and safe gaming where faith comes first! With TruPlay, important life lessons are seamlessly integrated into every game, comic, and animation.",
    buttonText: "Get Exclusive Offer",
    buttonLink: "/user-registration",
    coupon: true,
    couponDeal: { deal: "50% off", subscription: "Annual Subscription" },
    couponSubtext: "Only $4.99/Month",
    imageSrc: require("../global-assets/assets/holiday-marquee-snowflake.png"),
    starAccent: accent_stars_1,
  },
  {
    id: 2,
    title: "Benjamin and the Armor of Logos",
    description:
      "Benjamin is on a mission to find all of the pieces of the Armor of Logos, but vicious Smudz sent by the evil Queen Axilla stand in his way. He must rely on the power of prayer and courage that only comes from God to overcome his foes and recapture the lost armor.",
    buttonText: "Explore",
    buttonLink: "/games-more",
    imageSrc: require("../global-assets/assets/game-card-ben.webp"),
    newReleaseRibbon: require("../global-assets/assets/new-release-banner-top-left.webp"),
  },
  {
    id: 3,
    title: (
      <span>
        Gift <span className="hidden lg:inline">Truplay</span> a Subscription
      </span>
    ),
    description:
      "Say goodbye to destructive and addictive games and say hello to TruPlay, where game" +
      " time is God time. With TruPlay, children will embark on a digital journey of happy," +
      " healthy, and safe enrichment, where faith comes first, and important life lessons" +
      " are seamlessly integrated into game play.",
    buttonText: "Gift a Subscription",
    buttonLink: "/gift",
    imageSrc: require("../global-assets/assets/Gift_Image.png"),
  },
];

// TODO: Leaving this here for now. Currently only used in Trial1Lander.
// If we end up using FeaturedNews again, we can move it there.
export const MarketingHomeNews = [
  {
    id: 1,
    imageUrl: require("../global-assets/assets/huckabeelogo.png"),
  },
  {
    id: 2,
    imageUrl: require("../global-assets/assets/emblemwhite2.png"),
  },
  {
    id: 3,
    imageUrl: require("../global-assets/assets/layer1.png"),
  },
  {
    id: 4,
    imageUrl: require("../global-assets/assets/end_logo8.png"),
  },
  {
    id: 5,
    imageUrl: require("../global-assets/assets/p_white.png"),
  },
  {
    id: 6,
    imageUrl: require("../global-assets/assets/end_logo1.png"),
  },
];

export const MarketingContactEmails = {
  images: {
    curvewhite,
    contactherobg,
    curveblue,
    teambg,
  },
};

export const AffiliateCardsData = [
  {
    icon: Icon1,
    title: "Sign Up",
    description:
      "By following the link, You'll receive a confirmation email shortly after.",
  },
  {
    icon: Icon2,
    title: "Recommend",
    description:
      "Share TruPlay with your community and help spread faith while making money. Access customized linking tools and assets to help you succeed.",
  },
  {
    icon: Icon3,
    title: "Earn",
    description:
      "Earn commissions effortlessly and track your earnings with our intuitive dashboard.",
  },
];

export const AffiliateTestimonialData = [
  {
    title: "Family friendly FUN",
    description:
      "This amazing app is a MUST HAVE for parents and grandparents who are concerned about the content that children are being exposed to through digital games and entertainment. Kids will absolutely LOVE this platform!!",
    initials: "J",
  },
  {
    title: "FINALLY something my kids can play!!!",
    description:
      "As a parent I'm SO excited to see this!!! Games my kids can play that are safe for them! These games are really cool too, not cheesy or low quality and my kids keep asking to play ALL THE TIME. THANK YOU for making these games!!!",
    initials: "G",
  },
  {
    title: "Not just for kids",
    description:
      "Downloaded app and it's good as they have games for the older folk like me. Stained glass is a match 3 game that is at my pace. Also, they have a word game called Lumi's word of light which is very enjoyable and challenging.",
    initials: "L",
  },
];
