import Video from "../../common/Video";
import ThumbWhyWeCharge from "../../../global-assets/assets/thumbnail_why_we_charge.webp";

export default function AboutWhyWeCharge() {
  return (
    <div className="mx-auto max-w-[1440px]">
      <div className="relative mx-auto flex w-full flex-col items-center gap-12 lg:flex-row ">
        <div className="order-last mx-auto flex w-full flex-1 flex-col gap-10 lg:order-first">
          <Video
            id="why_we_charge_video"
            videoId={920536083}
            url="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/why_we_charge_a_subscription.%20(720p).mp4"
            shareUrl="https://vimeo.com/920536083"
            shareSmall
            controls
            playsInline
            thumbnail={ThumbWhyWeCharge}
          />
        </div>
        <div className="flex w-full flex-1 flex-col items-center gap-10 text-center lg:items-start lg:text-left">
          <h2 className="text-transparent max-w-[310px] bg-gradient_text bg-clip-text sm:max-w-none lg:max-w-[500px]">
            Why do we charge for a subscription?
          </h2>
          <p className="m-0 text-white">
            We charge a subscription fee to bring you high-quality Christian
            games, videos, and stories, prioritizing your child's safety. Plus,
            with just one fee, you won't have to worry about any extra charges
            or add-ons.
          </p>
        </div>
      </div>
    </div>
  );
}
