import { Subscription } from "../../../config/UserAuthContext";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ChevronRightBlue from "../assets/chevron-right-blue.svg";
import Button from "../../common/Button";

export default function AccountSubscriptionInfo(
  subscriptionData: Subscription
) {
  const [planDisplayName, setplanDisplayName] = useState<string>("");
  const navigate = useNavigate();
  const [canUpgradePlan, setCanUpgradePlan] = useState<boolean>(false);
  // TODO: Remove "false"/update logic when /select-plan allows modification
  const isManagable = false && subscriptionData.isActive && canUpgradePlan;
  const expirationDate = new Date(subscriptionData?.expireTime)
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
    .replace("T", " ");

  useEffect(() => {
    if (
      subscriptionData.isActive &&
      subscriptionData?.sources &&
      subscriptionData?.sources[0]
    ) {
      const planName = subscriptionData?.sources[0]?.productId.toLowerCase();

      const planMapping: { [key: string]: string } = {
        sixmonth: "Six Month",
        month: "Monthly Plan",
        annual: "Annual Plan",
        launchpromo: "Annual Launch Promotion",
      };

      for (const key in planMapping) {
        if (planName.includes(key)) {
          setplanDisplayName(planMapping[key as keyof typeof planMapping]);
          if (key === "month") {
            setCanUpgradePlan(true);
          }
          break;
        }
      }
    }
  }, [subscriptionData]);

  return (
    <div>
      <div
        className={
          "tru-accinfo-container-bg rounded-3xl rounded-b-none border-[3px]" +
          " border-b-0 border-solid border-x-[rgba(32,86,139,0.7)]" +
          " bg-gradient_acc_info border-t-[rgba(32,86,139,0.2)] p-4" +
          " shadow-[0px_0px_76px_0px_rgba(0,0,0,0.2)] md:p-8"
        }
      >
        <h2 className="mb-8 text-2xl tracking-wide text-white sm:text-4xl">
          Subscriptions
        </h2>

        <div
          className={
            "flex flex-col justify-between md:flex-row md:items-center md:text-2xl" +
            (isManagable ? " flex-row items-center" : "")
          }
        >
          <div>
            <div className="mb-4 text-lg font-semibold text-white md:text-2xl">
              Current Plan
            </div>

            <span className="flex flex-row flex-wrap font-normal text-[#ffffff4d]">
              {subscriptionData.isActive ? planDisplayName : "None"}
            </span>
          </div>

          {isManagable && (
            <div
              id="modify_subscription_btn"
              className="flex cursor-pointer flex-row items-center text-center"
              onClick={() => navigate("/select-plan", { state: "subscribe" })}
            >
              <h4 className="mr-1 cursor-pointer font-display text-base font-semibold tracking-wide text-cerulean-500 md:text-2xl">
                Manage
              </h4>
              <img
                src={ChevronRightBlue}
                alt="Right Arrow"
                className="ml-2 h-6 w-6 text-cerulean-500 md:h-8 md:w-8"
              />
            </div>
          )}

          {!subscriptionData.isActive && (
            <div className="mt-4 w-full self-baseline md:mt-0 md:w-[unset]">
              <Button
                id="subscribe_now_btn"
                onClick={() => navigate("/select-plan", { state: "subscribe" })}
                btnType="green"
                className="w-full md:w-[265px]"
              >
                Subscribe Now
              </Button>
            </div>
          )}
        </div>
      </div>

      <div
        className={
          "tru-accinfo-container-bg mt-1 rounded-3xl rounded-t-none border-[3px]" +
          " border-t-0 border-solid border-x-[rgba(32,86,139,0.7)]" +
          " bg-gradient_acc_info border-b-[rgba(32,86,139,1)] p-4" +
          " shadow-[0px_0px_76px_0px_rgba(0,0,0,0.2)] md:p-8"
        }
      >
        <div className="flex flex-row items-center justify-between">
          <div>
            <div className="mb-4 text-lg font-semibold text-white md:text-2xl">
              Next Billing Date
            </div>
            <span className="flex flex-row flex-wrap font-normal text-[#ffffff4d]">
              {subscriptionData.isActive ? expirationDate : "N/A"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
