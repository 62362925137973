import SectionHeader from "../common/SectionHeader";
import ThreeBlocksSection from "../common/ThreeBlocksSection";

const blocks = [
  {
    title: "7 Million Players",
    body: "The Original Lightside Games; Journey of Jesus, Journey of Moses, and Stained Glass.",
  },
  {
    title: "25K People Believed",
    body: "And dedicated or rededicated their lives to Christ.",
  },
  {
    title: "7 Millon Received",
    body: "Encouragement every time the games were played.",
  },
];

export default function SeeTheImpactJOJ() {
  return (
    <div className="flex flex-col gap-6 lg:gap-20">
      <SectionHeader
        headerType="h2"
        headerText="See the Impact of Journey of Jesus"
        starLocation="left"
      />
      <ThreeBlocksSection blocks={blocks} />
    </div>
  );
}
