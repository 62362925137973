import { useEffect, useState, useContext, useRef } from "react";

// Imports //
import Cookies from "js-cookie";

// Contexts //
import Utm from "../../components/analytics/UTM";
import { MixPanelAnalyticsContext } from "../../components/analytics/Analytics";
import TagManager from "react-gtm-module";
import { DeviceSessionContext } from "../../components/analytics/DeviceContext";
import {
  Context,
  GetOrCreateSessionAuthContext,
} from "../../config/UserAuthContext";
import { useFeatureFlags } from "../../config/FeatureFlagContext";

// Assets //
import DeanCain from "../../global-assets/assets/dean-cain-headshot.png";
import KevinSorbo from "../../global-assets/assets/kevin-sorbo-headshot.png";
import CandaceBure from "../../global-assets/assets/candace-cameron-burre-headshot.png";
import BGLandingImage from "../../global-assets/assets/BG_Image_Landing.webp";
import NewsHeadlines from "../../global-assets/assets/news-headlines.png";
import SoliersBackground from "../../global-assets/assets/soldiers-bg.png";
import PricePromo from "../../global-assets/assets/Hero_Trial1_Price_landingPage.webp";
import ThumbDeanCain from "../../global-assets/assets/thumbnail_dean_cain.webp";
import ThumbDiscoverTruPlay from "../../global-assets/assets/thumbnail_discover_truplay.webp";
import ThumbKevinSorbo from "../../global-assets/assets/thumbnail_kevin_sorbo.webp";
import ThumbTestimonies from "../../global-assets/assets/thumbnail_testimonies.webp";
import ThumbMovement from "../../global-assets/assets/thumbnail_join_the_movement.webp";
import HuckabeeLogo from "../../global-assets/assets/logo-huckabee-alt.png";
import CandacePodcastLogo from "../../global-assets/assets/logo-candace-bure-podcast.webp";
import ChristianityTodayLogo from "../../global-assets/assets/logo-christianity-today.png";
import PromiseKeepersLogo from "../../global-assets/assets/logo-promise-keepers.png";
import BillyGrahamLogo from "../../global-assets/assets/logo-billy-graham.svg";
import PluggedInLogo from "../../global-assets/assets/logo-plugged-in.webp";

// Components //
import RecommendedBy from "../../components/common/RecommendedBy";
import TrialAltNavBar from "../../components/trial/TrialAltNavBar";
import InlineUserRegistration from "../../components/welcome/InlineUserRegistration/InlineUserRegistration";
import AppReviewSlider from "../../components/common/AppReviewsSlider";
import Video from "../../components/common/Video";
import Button from "../../components/common/Button";
import SectionHeader from "../../components/common/SectionHeader";
import TextWithContentSplit from "../../components/common/TextWithContentSplit";
import TextWithVideoSection from "../../components/common/TextWithVideoSection";
import EndorsementsLogos from "../../components/home-landing/Endorsements/EndorsementsLogos";

/**
 * Login Page to authenticate users with valid email address on file
 *
 * @param props
 * @constructor
 */
interface LoginProps {
  setAuth: any;
  setLoginData: any;
}

const endorsedLogos = {
  christianityToday: { id: "christianityToday", image: ChristianityTodayLogo },
  candace: { id: "candace", image: CandacePodcastLogo },
  billy: { id: "billy", image: BillyGrahamLogo },
  pk: { id: "pk", image: PromiseKeepersLogo },
  pluggedIn: { id: "pluggedIn", image: PluggedInLogo },
  huckabee: { id: "huckabee", image: HuckabeeLogo },
};

export default function Trial1Gen({ setAuth, setLoginData }: LoginProps) {
  const mixPanelAnalytics = useContext(MixPanelAnalyticsContext);
  const initialUserContext = GetOrCreateSessionAuthContext();
  const [deviceCtx] = useState<DeviceSessionContext>(
    new DeviceSessionContext()
  );
  const [userContext] = useState(initialUserContext);
  const GlobalUserId = Cookies.get("GlobalUserId");
  const userRegRef = useRef<any>(null);
  const featureFlags = useFeatureFlags();
  const AppIconsFlag =
    featureFlags["2024-04 - Removal of AppStore buttons on webpage"]
      ?.variationId;
  const FreeTrial30 = featureFlags["2024-04 - 30 Day Free Trial"]?.variationId;
  const [freeTrial30, setFreeTrial30] = useState(false);

  Cookies.remove(Context.Pk);

  const DEAN_CAIN = {
    image: DeanCain,
    name: "Dean Cain",
    quote: `“TruPlay is a team who are fully committed to making
    high quality, engaging entertainment for today's kids.
    They've developed games that will attract and retain
    their attention. Games with positive messages that
    reinforce good values.”`,
  };

  const CANDACE_BURE = {
    image: CandaceBure,
    name: "Candace Cameron Bure",
    quote: `“If you're a parent or someone with young kids in your 
    life, you want your kids to be safe online. With TruPlay, kids 
    have fun in a world of safe and original content.”`,
  };

  const KEVIN_SORBO = {
    image: KevinSorbo,
    name: "Kevin Sorbo",
    quote: `“This is more than just an entertainment platform - it's 
    a sanctuary of hope and faith, a place where families can strengthen 
    their bonds through God's word. I love what TruPlay stands for and 
    the positive impact it offers to its users.”`,
  };

  useEffect(() => {
    Cookies.set(Context.FreeTrial, "true");

    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        page_name: "marketing: landing: fear_based-1",
        page_type: "marketing",
        os_type: deviceCtx.os.name,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    let utm = new Utm(mixPanelAnalytics);
    utm.track("Web: Fear-Based-Trial-1-Page-Viewed", deviceCtx);
  }, [
    deviceCtx,
    mixPanelAnalytics,
    userContext.subscription.sources,
    GlobalUserId,
  ]);

  const handleScrollToAccountCreation = () => {
    if (userRegRef.current) {
      const targetScrollPosition =
        userRegRef.current.getBoundingClientRect().top + window.scrollY - 120;
      window.scrollTo({
        top: targetScrollPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (FreeTrial30) {
      switch (FreeTrial30) {
        case "1":
          break;
        case "2":
          setFreeTrial30(true);
          break;
      }
    }
  }, [AppIconsFlag, FreeTrial30]);

  return (
    <div id="test_data_trial_alt_page">
      <TrialAltNavBar scrollTo={handleScrollToAccountCreation} />
      <div className="relative overflow-hidden">
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_72%_25%,#20568b_0%,transparent_50%)] opacity-80" />
        <div ref={userRegRef} />
        <div className="mx-auto flex max-w-[1440px] flex-col gap-8 px-5 pb-16 pt-28 sm:px-6 md:gap-12 md:px-7 lg:gap-28 lg:pb-28 lg:pt-40">
          <InlineUserRegistration
            setAuth={setAuth}
            setLoginData={setLoginData}
            freeTrial
            pageDetails={{
              pageName: "Trial 1 Gen Landing",
              pageType: "Marketing",
            }}
            image={PricePromo}
            header="Games Kids Want and Parents can Trust"
            checkmarkPoints={[
              "One app, multiple high-quality children games",
              "New games and features added several times per month",
              "Positive values reinforced in every piece of content",
              "Peace of mind: no ads, chat rooms, or in-app purchases after subscription",
            ]}
            ctaText={
              freeTrial30 ? "Start 30 Day Free Trial" : "Redeem Your Free Week"
            }
          />
          <div className="relative flex w-full flex-col gap-6 text-center">
            <h3 className="mx-auto max-w-[1100px] text-white">
              TruPlay is a mobile app that offers parents a high-quality,
              character and value-building alternative to the toxic kid's
              content that pervades today.
            </h3>
          </div>
          <div className="flex flex-col gap-12">
            <SectionHeader headerType="h2" headerText="Why now?" />
            <TextWithContentSplit
              headerText="TruPlay Games is a beacon of hope for responsible parents"
              headerTextColor="text-white"
              subtext="In a society where digital games and content are filled with
            inappropriate values & innuendos."
              content={
                <img src={NewsHeadlines} alt="TruPlay Games" loading="eager" />
              }
              subtextColor="text-white"
              contentLocation="right"
              textPositionOnMobile="above"
            />
          </div>
        </div>
      </div>
      <div className="relative overflow-hidden">
        <div className="mx-auto flex max-w-[1440px] flex-col gap-8 px-5 pb-28 pt-16 sm:px-6 md:gap-12 md:px-7 lg:gap-28 lg:py-28">
          <div className="flex flex-col gap-12">
            <SectionHeader
              headerType="h2"
              headerText="Decline of Faith and Rise of Despair in Our Young Generation"
              textColor="text-white"
              textLineNumber={2}
            />
            <TextWithVideoSection
              headerText=""
              hideHeaderOnMobile
              contentText="Anxiety, suicide, and depression in children are at all-time
              highs. Less than half of Americans now attend church, a
              startling decline unseen since the 1940s. Children are
              spending a staggering 52 and a half hours a week on screen
              time, absorbing content that's molding their worldview - and
              not for the better."
              contentTextColor="text-white"
              videoUrl="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/dean_cain%20(720p).mp4"
              videoId="872592906"
              videoThumbnail={ThumbDeanCain}
              videoShareUrl="https://vimeo.com/872592906"
              videoPosition="right"
              videoSectionTestId="trial1_dean_cain_video"
              textPositionOnMobile="above"
            />
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1]">
          <img
            src={SoliersBackground}
            alt=""
            loading="lazy"
            className="h-full w-full object-cover object-center"
          />
        </div>
      </div>
      <div className="relative overflow-hidden">
        <div className="mx-auto flex max-w-[1440px] flex-col items-center justify-center gap-8 px-5 pb-16 pt-0 sm:px-6 md:gap-12 md:px-7 lg:gap-28 lg:pb-28 lg:pt-28">
          <div className="relative flex w-full flex-col gap-12 text-center">
            <SectionHeader
              headerType="h2"
              headerText="Shaping Character and Faith Through Creative Gaming"
              subtext="A TruPlay subscription unlocks a whole RhymVerse of faith-filled
              adventure, featuring dozens of games, comics, and animated shorts
              designed with care to be engaging, entertaining and most
              importantly - true to the Word of God."
              starLocation="right"
              textLineNumber={2}
            />
            <div className="mx-auto flex w-full max-w-[850px] flex-col">
              <Video
                url="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/discover_truplay%20(720p).mp4"
                shareUrl="https://vimeo.com/911627416"
                shareSmall
                controls
                playsInline
                thumbnail={ThumbDiscoverTruPlay}
              />
            </div>
            <h3 className="text-white">
              Multiple games in one app, perfect for ages 4 to 12.
            </h3>
          </div>
          <RecommendedBy
            title="Trusted by..."
            person1={DEAN_CAIN}
            person2={CANDACE_BURE}
            person3={KEVIN_SORBO}
          />
          <div className="relative flex w-full flex-col gap-6">
            <div className="max-w-[2000px mx-auto">
              <EndorsementsLogos
                title="TruPlay is Endorsed by:"
                mobileTitle="Endorsed by:"
                noBackground
                logos={endorsedLogos}
              />
            </div>
            <div className="mx-auto flex w-full max-w-[850px] flex-col">
              <Video
                url="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/kevin_sorbo%20(720p).mp4"
                shareUrl="https://vimeo.com/905745758"
                shareSmall
                controls
                playsInline
                thumbnail={ThumbKevinSorbo}
              />
            </div>
            <Button
              id="trial_endorsed_by_cta"
              onClick={handleScrollToAccountCreation}
              btnType="green"
              className="mx-auto !px-10"
            >
              {freeTrial30
                ? "Start 30 Day Free Trial"
                : "Redeem Your Free Week"}
            </Button>
          </div>
        </div>
      </div>
      <div className="relative overflow-hidden pb-12 lg:pb-28">
        <div className="mx-auto flex max-w-[1440px] flex-col gap-8 px-5 pb-12 pt-16 sm:px-6 md:gap-12 md:px-7 lg:gap-28 lg:pt-28">
          <div className="relative flex w-full flex-col gap-12">
            <SectionHeader
              headerType="h2"
              headerText="What Our Customers Say"
              textColor="text-white"
              subtext="See what parents are saying about the all new Christian
            entertainment platform."
            />
            <div className="mx-auto flex w-full max-w-[850px] flex-col">
              <Video
                url="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/testimonies%20(720p).mp4"
                shareUrl="https://vimeo.com/896306896"
                shareSmall
                controls
                playsInline
                thumbnail={ThumbTestimonies}
                borderColor="bg-[#00203f66]"
              />
            </div>
          </div>
        </div>
        <div className="-mx-[20px] flex flex-col gap-16 sm:-mx-[25px] md:-mx-[30px] lg:-mx-[50px]">
          <AppReviewSlider noTitle lightBackground />
        </div>
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1]">
          <img
            src={SoliersBackground}
            alt=""
            loading="lazy"
            className="h-full w-full object-cover object-center"
          />
        </div>
      </div>
      <div className="relative overflow-hidden">
        <div className="mx-auto flex max-w-[1440px] flex-col gap-8 px-5 pb-12 pt-0 sm:px-6 md:gap-12 md:px-7 lg:gap-28 lg:pt-8">
          <div className="relative flex w-full flex-col gap-16 text-center">
            <SectionHeader
              headerType="h2"
              headerText="Empowering Parents, Uplifting Children"
              textColor="text-white"
              subtext="Take a stand for faith and a hopeful future. Subscribe to TruPlay
              and unlock a universe of faith-filled adventures. From epic games
              to engaging stories, every piece of content is crafted with care
              to build character and reinforce positive values."
            />
            <div className="mx-auto flex w-full max-w-[850px] flex-col">
              <Video
                url="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/join_the_movement%20(720p).mp4"
                shareUrl="https://vimeo.com/911651950"
                shareSmall
                controls
                playsInline
                thumbnail={ThumbMovement}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-auto left-0 right-0 top-0 -z-[3] mx-auto max-w-none overflow-hidden regmd:flex">
        <img
          src={BGLandingImage}
          loading="eager"
          sizes="100vw"
          alt=""
          className="relative -z-[1] w-full min-w-[1920px]"
        />
      </div>
    </div>
  );
}
