import BlueCaretDown from "../../global-assets/assets/icon-caret-down-cerulean.svg";
import AccentStars1 from "../../global-assets/assets/Accent_Stars_01.svg";
import AccentStars2 from "../../global-assets/assets/Accent_Stars_02.svg";
import { useState } from "react";
import Button from "./Button";

interface ITextWithContentSplitProps {
  headerText: string;
  headerTextColor?: string;
  subtext: JSX.Element | string;
  subtextColor?: string;
  content: JSX.Element | string;
  contentLocation: "left" | "right";
  textPositionOnMobile?: "above" | "below";
  hideHeaderOnMobile?: boolean;
  showAccentStars?: boolean;
  handleToggleReadMore?: boolean;
  contentPercentage?: number; // Percentage from 1-100 width of the content's space (50% of the page)
  hideContentOnMobile?: boolean;
  headerType?: string;
}

export default function TextWithContentSplit({
  headerText,
  headerTextColor = "text-transparent bg-gradient_text bg-clip-text",
  subtext,
  subtextColor = "text-white",
  content,
  contentLocation,
  textPositionOnMobile,
  hideHeaderOnMobile = false,
  showAccentStars,
  handleToggleReadMore = false,
  contentPercentage,
  hideContentOnMobile = false,
  headerType = "h3",
}: ITextWithContentSplitProps) {
  const [showMore, setShowMore] = useState(false);
  const isContentOnLeft = contentLocation === "left";
  const handleToggle = () => {
    setShowMore(!showMore);
  };

  const handleHeaderTag = (children: JSX.Element) => {
    if (headerType === "h1") {
      return (
        <h1
          className={`relative text-center text-4xl font-bold !leading-tight lg:text-left lg:text-5xl ${headerTextColor}`}
        >
          {children}
        </h1>
      );
    }
    if (headerType === "h2") {
      return (
        <h2
          className={`relative text-center text-3xl font-bold !leading-tight lg:text-left lg:text-4xl ${headerTextColor}`}
        >
          {children}
        </h2>
      );
    }
    if (headerType === "h3") {
      return (
        <h3
          className={`relative text-center text-2xl font-bold lg:text-left lg:text-3xl ${headerTextColor}`}
        >
          {children}
        </h3>
      );
    }
  };

  return (
    <div
      className={
        "flex flex-col items-center justify-center gap-6 lg:flex-row lg:gap-12" +
        (isContentOnLeft ? " lg:flex-row-reverse" : "")
      }
    >
      {textPositionOnMobile === "above" && (
        <div className="order-1 flex flex-1 flex-col gap-2 lg:hidden">
          {!hideHeaderOnMobile &&
            typeof headerText === "string" &&
            handleHeaderTag(
              <>
                {!isContentOnLeft && (
                  <img
                    src={AccentStars1}
                    alt="Accent Stars"
                    loading="eager"
                    className={
                      "absolute -left-16 -top-8 hidden lg:block" +
                      (!showAccentStars ? " !hidden" : "")
                    }
                  />
                )}
                {headerText}
                {isContentOnLeft && (
                  <img
                    src={AccentStars2}
                    alt="Accent Stars"
                    loading="eager"
                    className={
                      "absolute -right-16 -top-8 hidden lg:block" +
                      (!showAccentStars ? " !hidden" : "")
                    }
                  />
                )}
              </>
            )}
          {!hideHeaderOnMobile && typeof headerText !== "string" && headerText}
          {typeof subtext === "string" ? (
            <>
              <p
                className={`text-center text-lg font-normal lg:text-left lg:text-xl lg:font-semibold ${subtextColor} 
              ${!handleToggleReadMore ? "!line-clamp-none" : ""} 
              ${showMore ? "line-clamp-none" : "line-clamp-6"}`}
              >
                {subtext}
              </p>
              {handleToggleReadMore && (
                <Button
                  btnType="tertiaryAlt"
                  onClick={handleToggle}
                  rightIcon={
                    <img
                      src={BlueCaretDown}
                      alt="Caret"
                      loading="lazy"
                      className={`ml-4 h-6 w-6 duration-300 ${!showMore ? "rotate-180" : ""}`}
                    />
                  }
                  className="self-center md:hidden"
                >
                  {!showMore ? "Read More" : "Read Less"}
                </Button>
              )}
            </>
          ) : (
            subtext
          )}
        </div>
      )}

      <div
        className={
          "order-2 w-full" + (hideContentOnMobile ? " hidden lg:inline" : "")
        }
        style={{
          flex: contentPercentage ? contentPercentage / 100 : 1,
        }}
      >
        <div className="w-full">{content}</div>
      </div>

      {textPositionOnMobile === "below" && (
        <div className="order-3 flex flex-1 flex-col gap-2 lg:hidden">
          {!hideHeaderOnMobile &&
            typeof headerText === "string" &&
            handleHeaderTag(
              <>
                {!isContentOnLeft && (
                  <img
                    src={AccentStars1}
                    alt="Accent Stars"
                    loading="eager"
                    className={
                      "absolute -left-16 -top-8 hidden lg:block" +
                      (!showAccentStars ? " !hidden" : "")
                    }
                  />
                )}
                {headerText}
                {isContentOnLeft && (
                  <img
                    src={AccentStars2}
                    alt="Accent Stars"
                    loading="eager"
                    className={
                      "absolute -right-16 -top-8 hidden lg:block" +
                      (!showAccentStars ? " !hidden" : "")
                    }
                  />
                )}
              </>
            )}
          {!hideHeaderOnMobile && typeof headerText !== "string" && headerText}
          {typeof subtext === "string" ? (
            <>
              <p
                className={`text-center text-lg font-normal lg:text-left lg:text-xl lg:font-semibold ${subtextColor} 
              ${!handleToggleReadMore ? "!line-clamp-none" : ""} 
              ${showMore ? "line-clamp-none" : "line-clamp-6"}`}
              >
                {subtext}
              </p>

              {handleToggleReadMore && (
                <Button
                  btnType="tertiaryAlt"
                  onClick={handleToggle}
                  rightIcon={
                    <img
                      src={BlueCaretDown}
                      alt="Caret"
                      loading="lazy"
                      className={`ml-4 h-6 w-6 duration-300 ${!showMore ? "rotate-180" : ""}`}
                    />
                  }
                  className="self-center md:hidden"
                >
                  {!showMore ? "Read More" : "Read Less"}
                </Button>
              )}
            </>
          ) : (
            subtext
          )}
        </div>
      )}

      <div className="order-1 hidden flex-1 flex-col items-start justify-center gap-4 lg:flex lg:w-1/3">
        {typeof headerText === "string" &&
          handleHeaderTag(
            <>
              {!isContentOnLeft && (
                <img
                  src={AccentStars1}
                  alt="Accent Stars"
                  loading="eager"
                  className={
                    "absolute -left-16 -top-8 hidden lg:block" +
                    (!showAccentStars ? " !hidden" : "")
                  }
                />
              )}
              {headerText}
              {isContentOnLeft && (
                <img
                  src={AccentStars2}
                  alt="Accent Stars"
                  loading="eager"
                  className={
                    "absolute -right-16 -top-8 hidden lg:block" +
                    (!showAccentStars ? " !hidden" : "")
                  }
                />
              )}
            </>
          )}
        {typeof headerText !== "string" && headerText}
        {typeof subtext === "string" ? (
          <p
            className={`text-center text-lg font-normal lg:text-left lg:text-xl lg:font-semibold ${subtextColor}`}
          >
            {subtext}
          </p>
        ) : (
          subtext
        )}
      </div>
    </div>
  );
}
