// Hooks //
import useKlaviyo from "../../hooks/useKlaviyo";

// Components //
import Video from "../common/Video";
import HeroVisualTextSection from "../common/HeroVisualTextSection";

export default function SupportTheMission() {
  const formId =
    process.env.REACT_APP_ENVIRONMENT !== "prod" ? "XsdNGP" : "UDfA94";
  const klaviyoPubKey =
    process.env.REACT_APP_ENVIRONMENT !== "prod" ? "QPyxyX" : "STmu7s";

  const displayPopup = useKlaviyo(formId, klaviyoPubKey);

  const contentText = (
    <p className="m-0 text-lg text-white lg:text-xl">
      TruPlay plans to reverse the negative trends impacting kids in the digital
      age by meeting them where they are with content that builds character and
      nurtures faith. We are launching a Kickstarter to fuel this mission.
      <br />
      <br />
      The first 48 hours are crucial to the success of a Kickstarter campaign.
      Join the waitlist to get notified the moment we launch. Will you help?
    </p>
  );

  const video = (
    <Video
      id="video_support_out_mission"
      videoId="2zthzT5XNFc"
      url="2zthzT5XNFc"
      title="We're Bringing it BACK. Journey of Jesus. We Need Your Help."
      playsInline
      embedded="youTube"
    />
  );

  return (
    <HeroVisualTextSection
      sectionId="support_the_mission"
      headerText="Support The Mission"
      headerStyles="relative text-3xl sm:text-4xl md:text-5xl lg:inline lg:max-w-[250px] lg:text-4xl 2xl:max-w-[425px] 2xl:text-[2.3rem]"
      contentText={contentText}
      visualComponent={video}
      visualPosition="left"
      ctaText="Yes, I will!"
      ctaAction={displayPopup}
    />
  );
}
