import UnderlineSroke from "../../global-assets/assets/drawn-underline-stroke.png";
import RaisedHandsLeftDesktop from "../../global-assets/assets/raised-hands-banner-left-desktop.png";
import RaisedHandsRightDesktop from "../../global-assets/assets/raised-hands-banner-right-desktop.png";
import RaisedHandsLeftMobile from "../../global-assets/assets/raised-hands-banner-left-mobile.png";
import RaisedHandsRightMobile from "../../global-assets/assets/raised-hands-banner-right-mobile.png";

export default function NotificationBanner() {
  return (
    <div className="relative flex h-[86px] w-full items-center justify-center bg-cerulean-500 pb-3 pt-2 md:h-auto md:pb-6 md:pt-4">
      <div
        className="absolute left-0 top-0 z-[0] hidden 2xl:inline"
        style={{
          maskImage: `linear-gradient(
            -270deg,
            #000 0%,
            rgba(0, 0, 0, 0) 100%
          )`,
        }}
      >
        <img
          src={RaisedHandsLeftDesktop}
          alt=""
          loading="eager"
          className="h-[72px]"
        />
      </div>
      <div
        className="absolute left-0 top-0 z-[0] 2xl:hidden"
        style={{
          maskImage: `linear-gradient(
            -270deg,
            #000 0%,
            rgba(0, 0, 0, 0) 100%
          )`,
        }}
      >
        <img
          src={RaisedHandsLeftMobile}
          alt=""
          loading="eager"
          className="h-[86px] md:h-[72px]"
        />
      </div>
      <div className="z-[1] flex flex-col items-center md:flex-row md:gap-2">
        <p className="m-0 font-body text-lg font-medium text-white md:justify-end md:text-2xl">
          Find out more about our partnership with
        </p>
        <div className="relative mx-auto flex max-w-[160px] flex-col items-center justify-center md:max-w-none">
          <a
            className="cursor-pointer font-display text-lg font-bold text-white hover:drop-shadow-[0px_0px_16px_rgba(255,255,255,75%)] md:text-2xl"
            href="/promise-keepers"
            target="_blank"
            rel="noreferrer"
          >
            Promise Keepers
          </a>
          <img
            src={UnderlineSroke}
            alt=""
            loading="eager"
            className="md:absolute md:-bottom-[13px] lg:-bottom-[15px]"
          />
        </div>
      </div>
      <div
        className="absolute right-0 top-0 z-[0] hidden 2xl:inline"
        style={{
          maskImage: `linear-gradient(
            270deg,
            #000 0%,
            rgba(0, 0, 0, 0) 100%
          )`,
        }}
      >
        <img
          src={RaisedHandsRightDesktop}
          alt=""
          loading="eager"
          className="h-[72px]"
        />
      </div>
      <div
        className="absolute right-0 top-0 z-[0] 2xl:hidden"
        style={{
          maskImage: `linear-gradient(
            270deg,
            #000 0%,
            rgba(0, 0, 0, 0) 100%
          )`,
        }}
      >
        <img
          src={RaisedHandsRightMobile}
          alt=""
          loading="eager"
          className="h-[86px] md:h-[72px]"
        />
      </div>
    </div>
  );
}
