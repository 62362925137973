import ValuesChecklist from "../../common/ValuesChecklist";
import RegistraionForm from "./RegistrationForm";
import TextWithContentSplit from "../../common/TextWithContentSplit";

/**
 * Login Page to authenticate users with valid email address on file
 * @param props
 * @constructor
 */
interface InlineUserRegistrationProps {
  setAuth: any;
  setLoginData: any;
  freeTrial?: boolean;
  pageDetails?: {
    pageName: string;
    pageType: string;
  };
  checkmarkPoints: any[];
  image: string;
  header: string;
  ctaText: string;
  hideContentOnMobile?: boolean;
}

const InlineUserRegistration = ({
  setAuth,
  setLoginData,
  freeTrial,
  pageDetails,
  checkmarkPoints,
  image,
  header,
  ctaText,
  hideContentOnMobile = false,
}: InlineUserRegistrationProps) => {
  const contentText = (
    <div className="flex w-full flex-1 flex-col gap-10 text-white">
      <ValuesChecklist points={checkmarkPoints} />
      <RegistraionForm
        setAuth={setAuth}
        setLoginData={setLoginData}
        freeTrial={freeTrial}
        pageDetails={pageDetails}
        ctaText={ctaText}
      />
    </div>
  );

  return (
    <>
      <TextWithContentSplit
        headerType="h1"
        headerText={header}
        subtext={contentText}
        content={<img src={image} alt="" loading="eager" />}
        contentLocation="left"
        textPositionOnMobile="below"
        hideContentOnMobile={hideContentOnMobile}
      />
    </>
  );
};

export default InlineUserRegistration;
