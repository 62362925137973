import { useState } from "react";

// Components //
import SectionHeader from "../common/SectionHeader";
import FeaturedCarousel from "../common/FeaturedCarousel";
import Button from "../common/Button";

// Assets //
import BlueCaretDown from "../../global-assets/assets/icon-caret-down-cerulean.svg";
import ThumbKdb from "./assets/thumbnail_kdb.webp";
import ThumbBub from "./assets/thumbnail_bub.webp";
import ThumbMaple from "./assets/thumbnail_mpl.webp";
import ThumbBen from "./assets/thumbnail_ben.webp";
import Video from "../common/Video";
import LargePlayBtn from "./assets/branded_play_btn.svg";

interface IVideoTileList {
  id: string;
  videoId: string;
  tileImage: string; // Path to tile image
  videoLink: string; // URL to video (CDN link)
  thumbnail?: string;
  videoShareUrl?: string; // Link to shareable video (Vimeo link)
  component: JSX.Element | null;
  focus: boolean; // True if the carousel has focused tile logic
  type: string; // Type of tile
  title?: string | null;
  description?: string | null;
  visible: boolean; // True if within the container of visibility
  display: JSX.Element | null; // Element displayed when featured
}

const videoTileList: IVideoTileList[] = [
  {
    id: "tile_1",
    videoId: "936032354",
    tileImage: ThumbKdb,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/kdb_trailer%20(720p).mp4",
    thumbnail: ThumbKdb,
    videoShareUrl: "https://vimeo.com/936032354",
    component: null,
    focus: false,
    type: "video",
    display: null,
    visible: true,
  },
  {
    id: "tile_2",
    videoId: "953604909",
    tileImage: ThumbBub,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/chirp_song__wings_of_praise_-_official_trailer%20(720p).mp4",
    thumbnail: ThumbBub,
    videoShareUrl: "https://vimeo.com/953604909",
    component: null,
    focus: false,
    type: "video",
    display: null,
    visible: true,
  },
  {
    id: "tile_3",
    videoId: "823889094",
    tileImage: ThumbMaple,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/maple_trailer%20(720p).mp4",
    thumbnail: ThumbMaple,
    videoShareUrl: "https://vimeo.com/823889094",
    component: null,
    focus: false,
    type: "video",
    display: null,
    visible: true,
  },
  {
    id: "tile_4",
    videoId: "890078808",
    tileImage: ThumbBen,
    videoLink:
      "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/ben_trailer%20(720p).mp4",
    thumbnail: ThumbBen,
    videoShareUrl: "https://vimeo.com/890078808",
    component: null,
    focus: false,
    type: "video",
    display: null,
    visible: true,
  },
];

videoTileList.forEach((tile) => {
  tile.component = (
    <img
      src={tile.tileImage}
      alt={tile.id}
      loading="eager"
      draggable="false"
      className="rounded-lg sm:rounded-2xl lg:rounded-[20px]"
    />
  );
  tile.display = (
    <div
      id="tru-feature_our_journey"
      key={tile.videoLink}
      className="relative w-full"
    >
      <Video
        id="our_journey_featured_video"
        url={tile.videoLink}
        videoId={tile.videoId}
        shareUrl={tile.videoShareUrl}
        shareSmall
        playsInline
        thumbnail={tile.thumbnail}
        disableRemotePlayback
        customPlayBtn={LargePlayBtn}
        noShadow
      />
    </div>
  );
});

export default function OurJourneySoFar() {
  const [showAllText, setShowAllText] = useState<boolean>(false);
  const tileMargin = 16;
  const carouselPaddingTop = 16;
  const carouselPaddingBottom = 32;

  return (
    <div className="flex flex-col gap-6 lg:gap-20">
      <SectionHeader
        headerType="h2"
        headerText="Our Journey So Far"
        starLocation="right"
      />
      <div className="flex flex-col justify-center gap-8 lg:flex-row lg:gap-20">
        <div className="w-full flex-1 overflow-hidden lg:flex-[0.7_1_0]">
          <FeaturedCarousel
            id="our_journey"
            initialState={videoTileList}
            cardMargin={tileMargin}
            responsive
            animated
            cardStyles="shadow-lg rounded-lg sm:rounded-2xl lg:rounded-[20px] cursor-pointer duration-200 ease-in-out"
            hoverStyles="md:group-hover/card-hover:shadow-[0_10px_15px_-3px_rgba(56,166,213,0.60),0_4px_6px_-8px_#38A6D5] md:group-hover/card-hover:ring-white md:group-hover/card-hover:ring-4"
            focusStyles="rounded-lg sm:rounded-2xl lg:rounded-[20px] ring-[3px] sm:ring-4 ring-white cursor-pointer duration-200 ease-in-out shadow-[0_10px_15px_-3px_rgba(56,166,213,0.60),0_4px_6px_-8px_#38A6D5]"
            arrowType="featured"
            arrowCornerType="rounded"
            selection
            paddingBottom={carouselPaddingBottom}
            paddingTop={carouselPaddingTop}
            focus
            visibleCount={4}
          />
        </div>
        <div className="flex w-full flex-1 flex-col gap-6 lg:flex-[0.3_1_0]">
          <h3 className="text-center text-4xl text-white lg:text-left">
            Recent Games Launched
          </h3>
          <p
            className={
              "relative m-0 overflow-hidden text-center text-lg font-normal text-indigo-200 lg:text-left lg:text-xl lg:font-semibold" +
              (showAllText
                ? " line-clamp-none"
                : " md:line-clamp[8] line-clamp-6 lg:line-clamp-[12]")
            }
          >
            King David's Battles and Chirp Song: Wings of Praise are two of our
            most recent game releases, and the response has been phenomenal.
            Both parents and kids have embraced these games, experiencing the
            most epic Bible stories and the restorative power of praise. King
            David's Battles integrates narrative and gameplay to bring key
            biblical events to life, while Chirp Song: Wings of Praise, a bubble
            shooter set in the RhymVerse, has players freeing "Chirps" from
            Axilla's corruption, inspired by Psalm 150:6. Our mission is to
            create safe and transformative content that reinforces messages like
            “God loves you,” “You are special and made for a reason,” and “God
            is with you,” providing a meaningful experience.
          </p>
          <div className="flex items-center justify-center gap-4 lg:justify-end">
            <Button
              id="our_journey_read_more_btn"
              btnType="tertiaryAlt"
              onClick={() => setShowAllText(!showAllText)}
              rightIcon={
                <img
                  src={BlueCaretDown}
                  alt="Caret"
                  loading="lazy"
                  className={
                    "ml-4 h-6 w-6 duration-300" +
                    (showAllText ? " rotate-180" : "")
                  }
                />
              }
            >
              {!showAllText ? "Read More" : "Read Less"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
