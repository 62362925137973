import { useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import {
  EnvironmentConfig,
  EnvironmentConfigContext,
} from "../config/Environment";

export const getAuthBearerToken = () => {
  const storeString = Cookies.get("AuthContext");

  let authToken = "";
  if (storeString) {
    const store = JSON.parse(storeString);
    authToken = `Bearer ${store?.token}`;
  }
  return authToken;
};

/**
 * Account service manages TruPlay user interaction with the platform
 * 
 * @param endPoint
 * @param config
 * @param assumeAuthenticated
 * @constructor
 */
const AccountServiceApi = (endPoint: string, config: EnvironmentConfig, assumeAuthenticated: boolean = true) => {

  if (assumeAuthenticated) {
    return axios.create({
      baseURL: `${config.accountsUrl.baseUrl}${endPoint}`,
      headers: { Authorization: getAuthBearerToken() },
    });
  } else {
    return axios.create({
      baseURL: `${config.accountsUrl.baseUrl}${endPoint}`,
    });
  }
};

const AccountServiceClient = () => {
  // Assuming ConfigContext is properly set up and provided
  let environmentConfig = useContext(EnvironmentConfigContext) as EnvironmentConfig;

  /*
  // Unauthenticated calls to Account Service
  // These calls can be made successfully without a valid token issued by TruPlay's identity service
  */
  const AccountServiceUserLogin = AccountServiceApi("login", environmentConfig, false);
  const AccountServiceRegistration = AccountServiceApi("register", environmentConfig, false);
  const VerifyOTPToResetPassword = AccountServiceApi("reset-password-validate", environmentConfig, false);
  const GenerateStripeGiftLink = AccountServiceApi("subscription-web/create-checkout-session-gift", environmentConfig, false);

  /*
  // Authenticated Calls
  // These calls require a valid token issued by TruPlay's identity service
  */
  const GetEmailToResetPassword = AccountServiceApi("reset-password-begin", environmentConfig);
  const ResendOTPForVerification = AccountServiceApi("resend-verify-email", environmentConfig);
  const ForgotPassword = AccountServiceApi("reset-password-end", environmentConfig);
  const RefreshToken = AccountServiceApi("refresh-app-access", environmentConfig);
  const EnforcePasswordRules = AccountServiceApi("validate-password-format", environmentConfig);
  const VerifyEmailViaOTP = AccountServiceApi("verify-email", environmentConfig);
  const ChangePassword = AccountServiceApi("change-password", environmentConfig);
  const DeleteAccount = AccountServiceApi("mark-for-delete-pii", environmentConfig);
  const OptAPIout = AccountServiceApi("communications/opt-out", environmentConfig);
  const OptAPIin = AccountServiceApi("communications/opt-in", environmentConfig);
  const OptAPIstatus = AccountServiceApi("communications", environmentConfig);
  const StripeCheckout = AccountServiceApi("subscription-web/create-checkout-session", environmentConfig);
  const SubscriptionAPI = AccountServiceApi("subscription", environmentConfig);
  const RedeemGift = AccountServiceApi("subscription-web/redeem-subscription-gift", environmentConfig);

  return {
    AccountServiceUserLogin,
    AccountServiceRegistration,
    GetEmailToResetPassword,
    ResendOTPForVerification,
    VerifyOTPToResetPassword,
    ForgotPassword,
    VerifyEmailViaOTP,
    ChangePassword,
    DeleteAccount,
    OptAPIout,
    OptAPIin,
    OptAPIstatus,
    StripeCheckout,
    SubscriptionAPI,
    RefreshToken,
    EnforcePasswordRules,
    GenerateStripeGiftLink,
    RedeemGift,
  };
};

export default AccountServiceClient;
