import { useEffect } from "react";
// Hooks //
import useKlaviyo from "../../hooks/useKlaviyo";

// Components //
import Button from "../common/Button";

export default function HowItWorksCTA() {
  const formId =
    process.env.REACT_APP_ENVIRONMENT !== "prod" ? "XsdNGP" : "UDfA94";
  const klaviyoPubKey =
    process.env.REACT_APP_ENVIRONMENT !== "prod" ? "QPyxyX" : "STmu7s";

  const displayPopup = useKlaviyo(formId, klaviyoPubKey);
  return (
    <div className="flex flex-col items-center justify-center bg-white/5 px-8 py-16 shadow-[0_0_80px_0_#001b36] sm:px-6 sm:pb-16 md:px-7 md:py-24">
      <div className="flex flex-col gap-6 lg:gap-12">
        <div className="flex flex-col gap-4">
          <h3 className="text-center text-2xl text-white sm:text-3xl lg:text-4xl">
            Yes! Reserve my spot!
          </h3>
          <p className="m-0 text-center text-xl font-normal text-indigo-200">
            I want to receive updates on the launch project on Kickstarter.
          </p>
        </div>
        <Button
          id="how_it_works_cta"
          className="w-full"
          btnType="green"
          onClick={displayPopup}
        >
          Join The Waitlist
        </Button>
      </div>
    </div>
  );
}
