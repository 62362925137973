import { useContext, useEffect, useRef, useState } from "react";
import "react-tooltip/dist/react-tooltip.css";
import ApiFunction from "../../lib/ApiFunction";
import Cookies from "js-cookie";
import { Context, navigateTo } from "../../config/UserAuthContext";
import EmailValidator, {
  PasswordValidator,
} from "../../config/UserInputValidator";
import { InlineErrorMessage } from "../../components/notification/InlineErrorMessage/InlineErrorMessage";
import { ContinueTo } from "../../lib/PassedParams";
import TagManager from "react-gtm-module";
import { GetOrCreateSessionAuthContext } from "../../config/UserAuthContext";
import {
  MixPanelAnalyticsContext,
  Properties,
} from "../../components/analytics/Analytics";
import RegNavUnauth from "../../components/nav/RegNavUnauth/RegNavUnauth";
import { ErrorCodeMap } from "../../lib/Constants";
import { useFeatureFlags } from "../../config/FeatureFlagContext";
import Button from "../../components/common/Button";

// Firebase
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { useRemoteConfig } from "../../config/FBRemoteConfigContext";

// Assets
import BGLandingImage from "../../global-assets/assets/BG_Image_Landing.webp";
import EmailIcon from "../../global-assets/assets/icon-email.svg";
import PasswordIcon from "../../global-assets/assets/icon-password.svg";
import eyeShowIcon from "../../global-assets/assets/icon-eye-show.svg";
import eyeHideIcon from "../../global-assets/assets/icon-eye-hide.svg";
import BackCaret from "./assets/back-caret.svg";
import YesLadderProgressBar from "../../components/registration/YesLadderProgressBar";

/**
 * Login Page to authenticate users with valid email address on file
 *
 * @param props
 * @constructor
 */
interface UserRegistrationProps {
  setAuth: any;
  setLoginData: any;
  setIsEmailSignUp?: (isEmailSignUp: boolean) => void;
}

export default function UserRegistration({
  setAuth,
  setLoginData,
  setIsEmailSignUp,
}: UserRegistrationProps) {
  const analyticsContext = useContext(MixPanelAnalyticsContext);
  const firebaseFlags = useRemoteConfig();
  const featureFlags = useFeatureFlags();
  const { AccountServiceRegistration, EnforcePasswordRules } = ApiFunction();

  interface ErrorObj {
    [key: string]: string | React.ReactNode;
  }

  // Attributes bound to state
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string | React.ReactNode | null>(
    null
  );
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<
    string | React.ReactNode | null
  >(null);
  const [hasPasswordValidationError, setHasPasswordValidationError] =
    useState<boolean>(false);
  const [hasEmailValidationError, setEmailValidationError] =
    useState<boolean>(false);
  const [accountCreationError, setAccountCreationError] = useState<
    string | null
  >(null);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isCallingAPI, setIsCallingAPI] = useState<boolean>(false);
  const [enabledCreateAccountButton, setEnabledCreateAccountButton] =
    useState<boolean>(false);

  const [isCookiesBlocked, setIsCookiesBlocked] = useState<boolean>(false);

  const emailRef = useRef<any>(null);
  const passwordRef = useRef<any>(null);

  const userContext = GetOrCreateSessionAuthContext();
  const GlobalUserId = Cookies.get("GlobalUserId");
  const osType = navigator.userAgent;

  const subscriptionInfo = userContext.subscription.sources;

  const YesLadderFlag =
    featureFlags[
      "2024-07 - Winner of Yes Ladder Onboarding vs. Yes Ladder with Age Question Removed"
    ]?.variationId;
  const [yesLadderTest, setYesLadderTest] = useState<boolean>(false);

  // With Free Trial available for the whole site, just adding this here
  // to ensure it's active
  Cookies.set(Context.FreeTrial, "true");

  useEffect(() => {
    try {
      setIsCookiesBlocked(false);
    } catch (e) {
      setIsCookiesBlocked(true);
      TagManager.dataLayer({
        dataLayer: {
          event: "cookies_error_shown",
          cookies_consent: "cookies_blocked",
          page_name: "User-Registration",
          page_type: "Marketing",
          custom_user_id: GlobalUserId || undefined,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (email && password.length > 0) {
      setEnabledCreateAccountButton(true);
    } else {
      setEnabledCreateAccountButton(false);
    }
  }, [email, password]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        page_name: "accounts: register",
        page_type: "accounts",
        os_type: osType,
        plan_type: subscriptionInfo[0] || "none",
      },
    });
  }, [osType, subscriptionInfo]);

  useEffect(() => {
    switch (YesLadderFlag) {
      case "1":
        break;
      case "2":
        setYesLadderTest(true);
        break;
    }
  }, [YesLadderFlag]);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateRegistrationCredentials = () => {
    let errors: ErrorObj = {};

    let emailValidator = new EmailValidator(email);
    if (emailValidator.ErrorMessage) {
      errors.email = emailValidator.ErrorMessage;
      setEmailValidationError(true);
    }

    let passwordValidator = new PasswordValidator(password);
    if (passwordValidator.ErrorMessage) {
      errors.password = passwordValidator.ErrorMessage;
      setHasPasswordValidationError(true);
    }

    EnforcePasswordRules.post("", { password: password })
      .then((response) => {
        if (!response.data.isValid) {
          errors.password = (
            <>
              Password requires: 8-16 characters, 1 uppercase letter, 1
              lowercase letter, and 1 number
            </>
          );
          // "- Passwords must be between 8 and 16 characters, include 1 uppercase letter, 1 lowercase letter, and 1 number";
          setEmailError(errors.email);
          setPasswordError(errors.password);
        }
      })
      .catch((error) => {});

    setEmailError(errors.email);
    setPasswordError(errors.password);
    return errors;
  };

  /**
   * Function to process response on successful account creation
   * @param response
   */
  const onSuccessfulAccountCreation = (response: any) => {
    let { uuId, token } = response.data;

    Cookies.set(
      Context.AuthContext,
      JSON.stringify({ userID: uuId, token: token })
    );

    Cookies.set(Context.UserContext, JSON.stringify(response.data));

    Cookies.set("EmailAddress", email);

    // User is authenticated
    setAuth(true);
    setLoginData(response.request.data);

    let attribution = localStorage.getItem("tru-attribution") as string;
    let campaignAttribution: Properties = {};
    if (attribution) {
      campaignAttribution = JSON.parse(attribution) as Properties;
    }

    analyticsContext.track(
      "Web: Account-Creation-Successful",
      campaignAttribution
    );

    TagManager.dataLayer({
      dataLayer: {
        event: "sign_up",
        plan_type: subscriptionInfo[0] || "none",
        os_type: osType,
        method: "email",
        page_name: "user-registration",
        page_type: "marketing",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    if (yesLadderTest) {
      navigateTo("/user-registration-questions/2");
      return;
    }

    // look for continueto param, go there instead if we have one
    if (ContinueTo()) {
      analyticsContext.track(
        "Web: Redirect-After-Account-Creation",
        campaignAttribution
      );
      navigateTo(ContinueTo() || "");
    } else {
      analyticsContext.track(
        "Web: Select-Plan-After-Account-Creation-Viewed",
        campaignAttribution
      );
      navigateTo("/user-registration-questions/1");
    }

    setTimeout(() => {
      setIsCallingAPI(false);
    }, 30000);
  };

  const validateEmailPassword = () => {
    setHasPasswordValidationError(false);
    setEmailValidationError(false);

    let credentialValidationError = validateRegistrationCredentials();
    setEmailError(credentialValidationError.email);
    setPasswordError(credentialValidationError.password);

    if (Object.keys(credentialValidationError).length > 0) {
      return;
    }
  };

  const createNewFirebaseAccount = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        content_type: "link",
        os_type: osType,
        link_name: "Create Account",
        link_url: "/user-registration-questions",
        plan_type: subscriptionInfo[0] || "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    validateEmailPassword();

    setIsCallingAPI(true);

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user: any = userCredential.user;
        Cookies.set(
          Context.AuthContext,
          JSON.stringify({ userID: user.uid, token: user.accessToken })
        );
        const userData: any = {
          communicationsOptIn: false,
          subscription: {
            isActive: false,
            expireTime: null,
            sources: Array(0),
          },
          token: user.accessToken,
          uuId: user.uid,
          verifyEmail: {
            isVerified: false,
            didSendNewVerificationEmail: false,
            shouldShowEmailVerificationScreen: true,
          },
        };
        Cookies.set(Context.UserContext, JSON.stringify(userData));
        Cookies.set("EmailAddress", email);

        // User is authenticated
        setAuth(true);
        setLoginData(userData);

        let attribution = localStorage.getItem("tru-attribution") as string;
        let campaignAttribution: Properties = {};
        if (attribution) {
          campaignAttribution = JSON.parse(attribution) as Properties;
          console.log(campaignAttribution);
        }

        analyticsContext.track(
          "Web: Account-Creation-Successful",
          campaignAttribution
        );

        TagManager.dataLayer({
          dataLayer: {
            event: "sign_up",
            plan_type: subscriptionInfo[0] || "none",
            os_type: osType,
            method: "email",
            page_name: "user-registration",
            page_type: "marketing",
            custom_user_id: GlobalUserId || undefined,
          },
        });

        if (yesLadderTest) {
          navigateTo("/user-registration-questions/2");
          return;
        }

        // look for continueto param, go there instead if we have one
        if (ContinueTo()) {
          analyticsContext.track(
            "Web: Redirect-After-Account-Creation",
            campaignAttribution
          );
          navigateTo(ContinueTo() || "");
        } else {
          analyticsContext.track(
            "Web: Select-Plan-After-Account-Creation-Viewed",
            campaignAttribution
          );
          navigateTo("/user-registration-questions/1");
        }

        setTimeout(() => {
          setIsCallingAPI(false);
        }, 30000);
      })
      .catch((error) => {
        // TODO: Update error handling once Firebase and Account Services are fully integrated
        const fbErrorCode = error.code;
        const fbErrorMessage = error.message;
        const errorMessage = ErrorCodeMap[fbErrorCode]
          ? ErrorCodeMap[fbErrorCode]
          : fbErrorMessage;
        setAccountCreationError(errorMessage);
        setIsCallingAPI(false);
      });
  };

  /**
   * Create new Truplay Account
   */
  const createNewAccount = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        content_type: "link",
        os_type: osType,
        link_name: "Create Account",
        link_url: "/user-registration-questions",
        plan_type: subscriptionInfo[0] || "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    validateEmailPassword();

    setIsCallingAPI(true);

    let data = {
      emailAddress: email,
      password: password,
    };

    AccountServiceRegistration.post("", data)
      .then(onSuccessfulAccountCreation)
      .catch((error) => {
        setIsCallingAPI(false);

        let errorMessage = ErrorCodeMap[error.response?.data.statusCode]
          ? ErrorCodeMap[error.response?.data.statusCode]
          : error.response?.data.statusMessage;

        if (error.response.data.statusCode === "AS020") {
          analyticsContext.track(
            "Web: Account-Creation-Failed-Invalid-Credentials"
          );
          setEmailValidationError(true);
        }

        setAccountCreationError(errorMessage);
      });
  };

  return (
    <>
      <RegNavUnauth showMobileLogo={firebaseFlags.firebaseSSO ? false : true} />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] min-h-screen bg-white regmd:hidden" />
      <div className="tru-register-flow-purple-bg absolute bottom-0 left-0 right-0 top-0 -z-[2] hidden opacity-50 regmd:block" />
      <div className="tru-register-flow-blue-bg absolute bottom-0 left-0 right-0 top-0 -z-[2] hidden opacity-40 regmd:block" />
      <div className="tru-register-flow-middle-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] hidden opacity-50 regmd:block" />
      <div
        id="test_data_registration_page"
        className="relative min-h-screen regmd:flex regmd:items-center regmd:justify-center regmd:py-44"
      >
        {typeof setIsEmailSignUp === "function" && firebaseFlags.firebaseSSO ? (
          <img
            alt="Back"
            src={BackCaret}
            loading="eager"
            className="absolute left-6 top-10 z-[101] h-6 w-6 rotate-90 cursor-pointer opacity-80 hover:opacity-100 sm:top-9 sm:h-8 sm:w-8 regmd:hidden"
            onClick={() => setIsEmailSignUp(false)}
          />
        ) : null}
        <div
          className={
            "regmd:min-h-[unset] regmd:max-w-[515px] regmd:rounded-[2rem] regmd:bg-white regmd:px-10 regmd:py-8" +
            " relative mx-auto flex min-h-screen max-w-[450px] flex-col gap-4 px-6 pb-0 pt-32 regmd:gap-6"
          }
        >
          {typeof setIsEmailSignUp === "function" &&
          firebaseFlags.firebaseSSO ? (
            <img
              alt="Back"
              src={BackCaret}
              loading="eager"
              className="absolute left-8 top-8 z-[101] hidden h-8 w-8 rotate-90 cursor-pointer opacity-80 hover:opacity-100 regmd:!block"
              onClick={() => setIsEmailSignUp(false)}
            />
          ) : null}
          <h4
            className={
              "text-center text-indigo-500" +
              (!firebaseFlags.firebaseSSO ? " hidden text-3xl regmd:block" : "")
            }
          >
            {firebaseFlags.firebaseSSO
              ? "Sign Up With Email"
              : "Create Account"}
          </h4>
          <YesLadderProgressBar progress={0.2} />
          {!firebaseFlags.firebaseSSO && (
            <div className="text-center">
              <h4 className="mt-4 text-center text-indigo-500  regmd:hidden">
                Create Your Account
              </h4>
              <p className="m-0 mt-2 text-cool-gray-900 regmd:mt-0">
                Play across multiple devices with a single account
              </p>
            </div>
          )}
          <div className="relative flex w-full flex-col gap-2">
            <div className="relative flex w-full items-center">
              <img
                className="absolute left-2.5 z-[3] w-6"
                src={EmailIcon}
                alt=""
                loading="eager"
              />
              <input
                id="registration_email_input"
                className={
                  "relative flex w-full rounded-lg border-0 bg-cool-gray-200" +
                  " px-2.5 py-4 text-left text-base text-indigo-500 outline-none" +
                  " placeholder:text-cool-gray-900/60 focus:bg-cool-gray-300" +
                  " indent-8 focus:ring-2 focus:ring-cerulean-500 focus:placeholder:text-cool-gray-900/80" +
                  (hasEmailValidationError
                    ? " ring-2 ring-raspberry-500"
                    : "") +
                  (isCookiesBlocked ? " cursor-not-allowed opacity-50" : "")
                }
                ref={emailRef}
                placeholder="Email"
                value={email}
                onChange={(e: any) => {
                  const trimmedEmail = e.target.value.replace(/\s/g, "");
                  setEmail(trimmedEmail);
                  setEmailValidationError(false);
                }}
              />
            </div>
            <div className="relative flex w-full items-center">
              <img
                className="absolute left-2.5 z-[3] w-6"
                src={PasswordIcon}
                alt=""
                loading="eager"
              />
              <input
                id="registration_password_input"
                className={
                  "relative flex w-full rounded-lg border-0 bg-cool-gray-200" +
                  " px-2.5 py-4 text-left text-base text-indigo-500 outline-none" +
                  " placeholder:text-cool-gray-900/60 focus:bg-cool-gray-300" +
                  " indent-8 focus:ring-2 focus:ring-cerulean-500 focus:placeholder:text-cool-gray-900/80" +
                  (hasPasswordValidationError
                    ? " ring-2 ring-raspberry-500"
                    : "") +
                  (isCookiesBlocked ? " cursor-not-allowed opacity-50" : "")
                }
                ref={passwordRef}
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  const trimmedPassword = e.target.value.replace(/\s/g, "");
                  setPassword(trimmedPassword);
                  setHasPasswordValidationError(false);
                }}
              />
              <div className="absolute right-1.5 top-4">
                <div
                  className="flex items-center justify-center"
                  role="button"
                  onClick={handlePasswordVisibility}
                >
                  {password ? (
                    showPassword ? (
                      <img
                        src={eyeShowIcon}
                        alt="Show Password"
                        loading="lazy"
                        className="w-[22px] cursor-pointer text-cerulean-500"
                      />
                    ) : (
                      <img
                        src={eyeHideIcon}
                        alt="Hide Password"
                        loading="lazy"
                        className="w-[22px] cursor-pointer text-cerulean-500"
                      />
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {emailError || passwordError ? (
              <>
                {emailError && <InlineErrorMessage errorMessage={emailError} />}
                {passwordError && (
                  <InlineErrorMessage errorMessage={passwordError} />
                )}
              </>
            ) : null}
            {!passwordError && (
              <label className="text-sm font-normal text-cool-gray-800">
                Password requires: 8-16 characters, 1 uppercase letter, 1
                lowercase letter, and 1 number.
              </label>
            )}
            {isCookiesBlocked && (
              <InlineErrorMessage
                errorMessage={
                  "Oh no! You blocked all cookies, so you won't " +
                  "be able to see anything. You must enable cookies " +
                  "in your browser settings to be able to create an account. " +
                  "Once you do, give the page a refresh."
                }
              />
            )}
            {accountCreationError && (
              <InlineErrorMessage errorMessage={accountCreationError} />
            )}
          </div>
          <div className="absolute bottom-0 left-0 flex w-full flex-col gap-4 px-6 pb-8 regmd:static regmd:p-0">
            <label className="mx-auto max-w-[375px] text-center text-sm font-normal text-gray-900">
              By Proceeding you are agreeing to our
              <a
                className="font-body text-sm font-bold text-cerulean-500 no-underline hover:underline"
                rel="noreferrer"
                href="/terms-and-conditions"
                target="_blank"
              >
                {" "}
                Terms of Service{" "}
              </a>
              and
              <a
                className="font-body text-sm font-bold text-cerulean-500 no-underline hover:underline"
                rel="noreferrer"
                href="/privacy-policy"
                target="_blank"
              >
                {" "}
                Privacy Policy
              </a>
            </label>
            <Button
              id="registration_page_submit"
              loading={isCallingAPI}
              disabled={!enabledCreateAccountButton}
              onClick={
                firebaseFlags.firebaseAuth
                  ? createNewFirebaseAccount
                  : createNewAccount
              }
              btnType="green"
              className="w-full"
            >
              Continue
            </Button>
          </div>
        </div>
        <div className="absolute bottom-auto left-0 right-0 top-0 -z-[3] mx-auto max-w-none overflow-hidden regmd:flex">
          <img
            src={BGLandingImage}
            loading="eager"
            sizes="100vw"
            alt=""
            className="relative -z-[1] w-full min-w-[1920px]"
          />
        </div>
      </div>
    </>
  );
}
