import SectionHeader from "../common/SectionHeader";
import ThreeBlocksSection from "../common/ThreeBlocksSection";

const blocks = [
  {
    title: "Team Talent",
    body: "We need to attract and retain top notch talent to create, design, market, and produce great content with beauty and professionalism.",
  },
  {
    title: "Resource & Development",
    body: "Your support will not only help us fund and develop future games, but accelerate our production and distribution across the world. Journey of Jesus will be the most ambitious project yet and we will need your help to bring it to life.",
  },
  {
    title: "Spread the Word",
    body: "With increased marketing efforts we will share our mission and our solution to families all over the world. Please help us positively impact our culture by changing mindless entertainment into mindful engagement.",
  },
];

export default function WhySupportMatters() {
  return (
    <div className="flex flex-col gap-6 lg:gap-20">
      <SectionHeader
        headerType="h2"
        headerText="Why Your Support Matters"
        starLocation="left"
      />
      <ThreeBlocksSection blocks={blocks} />
    </div>
  );
}
