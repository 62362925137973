export default function CustomerReviewBreak() {
  return (
    <div className="flex flex-col items-center justify-center gap-4 bg-white/5 px-8 py-16 shadow-[0_0_80px_0_#001b36] sm:px-6 sm:pb-16 md:px-7 md:py-24">
      <p className="m-0 text-center text-lg text-white lg:text-3xl lg:!leading-normal">
        "A great platform for reaching this generation with the relevance of
        technology by the word of God."
      </p>
      <p className="m-0 text-center text-lg font-normal text-indigo-200 lg:text-2xl lg:font-semibold">
        - Customer Review, May 2024
      </p>
    </div>
  );
}
