import React from "react";

// Components //
import Button from "../../components/common/Button";

interface CtaImageCardProps {
  image: string;
  title: string;
  description: string;
  imagePosition?: "left" | "right";
  showButton?: boolean;
  onButtonClick?: () => void;
  buttonText?: string;
  buttonId?: string;
}

const CtaImageCard: React.FC<CtaImageCardProps> = ({
  image,
  title,
  description,
  imagePosition = "left",
  showButton = true,
  buttonId,
  buttonText = "Click Here",
  onButtonClick,
}) => {
  return (
    <div
      className={`shadow-more_games_card flex flex-col items-center rounded-[40px] bg-[rgba(255,255,255,0.06)] backdrop-blur-lg md:flex-row md:items-stretch md:gap-0 ${imagePosition === "right" ? "md:flex-row-reverse" : ""}`}
    >
      <div
        className={`w-full flex-shrink-0 md:w-[40%] ${imagePosition === "right" ? "md:rounded-r-[40px]" : "md:rounded-l-[40px]"} rounded-t-[40px] md:rounded-none`}
      >
        <img
          src={image}
          alt={title}
          className={`h-full w-full rounded-t-[40px] object-cover md:rounded-none ${imagePosition === "right" ? "md:rounded-r-[40px]" : "md:rounded-l-[40px]"} `}
        />
      </div>
      <div className="flex flex-grow flex-col gap-3 p-6 md:gap-4 md:p-10">
        <h3 className="font-display text-2xl font-bold text-cerulean-500 md:text-4xl">
          {title}
        </h3>
        <p className="text-lg text-white md:text-2xl">{description}</p>
        {showButton && (
          <Button
            id={buttonId}
            btnType="green"
            className={`md:min-w-[60%] ${imagePosition === "right" ? "md:self-start" : "md:self-end"}`}
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default CtaImageCard;
