// Assets //
import ThumbSupportOurMission from "./assets/thumbnail_support_our_mission.webp";

// Components //
import SectionHeader from "../common/SectionHeader";
import TextWithVideoSection from "../../components/common/TextWithVideoSection";

const WhereWereGoing = () => {
  return (
    <div className="flex flex-col gap-6 lg:gap-20">
      <SectionHeader
        headerType="h2"
        headerText="Where We're Going"
        subtext="At TruPlay, we are passionate about gaming and our children's future. Through Biblical stories and storytelling, our games bring God's Truth to the forefront of where they spend their time."
        textColor="gradient"
        starLocation="left"
      />
      <TextWithVideoSection
        headerText="Our Vision and Content"
        contentText="Each game in our library introduces kids to scripture and reminds them of their identity in Jesus. Our games have foundational themes such as prayer, friendship, empathy, courage, and so much more. Journeying through the RhymVerse, our kids will experience the love of God. "
        videoUrl="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/support_truplay_games%20(720p).mp4"
        videoId="975730641"
        videoThumbnail={ThumbSupportOurMission}
        videoShareUrl="https://vimeo.com/975730641"
        videoPosition="right"
        videoSectionTestId="where_were_going"
      />
    </div>
  );
};

export default WhereWereGoing;
