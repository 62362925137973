import { useState } from "react";
import accentStars2 from "../../global-assets/assets/Accent_Stars_02.svg";
import Button from "./Button";

interface Person {
  image: string;
  name: string;
  quote: string;
}
interface RecommendedByProps {
  title?: string;
  person1: Person;
  person2: Person;
  person3: Person;
}

export default function RecommendedBy({
  title,
  person1,
  person2,
  person3,
}: RecommendedByProps) {
  const [showQuote1, setShowQuote1] = useState(false);
  const [showQuote2, setShowQuote2] = useState(false);
  const [showQuote3, setShowQuote3] = useState(false);

  return (
    <div className="flex flex-col gap-6">
      <div className="text-center">
        <h2 className="text-transparent relative bg-gradient_text bg-clip-text">
          {title ? title : "Recommended By..."}
          <img
            src={accentStars2}
            loading="lazy"
            alt=""
            className="absolute -top-[40px] -ml-[15px] hidden lg:inline"
          />
        </h2>
      </div>
      <div className="flex flex-col gap-6 lg:flex-row">
        <div className="">
          <div
            className="group relative mb-6 flex md:max-w-[400px]"
            onClick={() => setShowQuote1(!showQuote1)}
          >
            <img
              className={
                "rounded-[30px] lg:group-hover:opacity-0" +
                (showQuote1 ? " opacity-0" : " opacity-100")
              }
              src={person1.image}
              loading="lazy"
              alt=""
            />
            <div
              className={
                "absolute flex h-full w-full cursor-default items-center justify-center rounded-[30px] bg-white/[6%] px-6 lg:group-hover:opacity-100" +
                (showQuote1 ? " opacity-100" : " opacity-0")
              }
            >
              <p className="text-center text-white lg:text-base xl:text-lg 2xl:text-2xl">
                {person1.quote}
              </p>
            </div>
            <div className="absolute bottom-5 flex w-full items-center justify-center lg:hidden">
              <Button
                id="recommended_by_quote1_btn"
                onClick={() => setShowQuote1(!showQuote1)}
                btnType="blue"
                className={
                  "!p-2 leading-none tracking-widest" +
                  (showQuote1 ? " hidden" : " inline-block")
                }
              >
                Read
              </Button>
            </div>
          </div>
          <h4 className="text-transparent bg-gradient_text bg-clip-text text-center">
            {person1.name}
          </h4>
        </div>
        <div>
          <div
            className="group relative mb-6 flex md:max-w-[400px]"
            onClick={() => setShowQuote2(!showQuote2)}
          >
            <img
              className={
                "rounded-[30px] lg:group-hover:opacity-0 " +
                (showQuote2 ? " opacity-0" : " opacity-100")
              }
              src={person2.image}
              loading="lazy"
              alt=""
            />
            <div
              className={
                "absolute flex h-full w-full cursor-default items-center justify-center rounded-[30px] bg-white/[6%] px-6 lg:group-hover:opacity-100" +
                (showQuote2 ? " opacity-100" : " opacity-0")
              }
            >
              <p className="text-center text-white lg:text-base xl:text-lg 2xl:text-2xl">
                {person2.quote}
              </p>
            </div>
            <div className="absolute bottom-5 flex w-full items-center justify-center lg:hidden">
              <Button
                id="recommended_by_quote1_btn"
                onClick={() => setShowQuote2(!showQuote2)}
                btnType="blue"
                className={
                  "!p-2 leading-none tracking-widest" +
                  (showQuote2 ? " hidden" : " inline-block")
                }
              >
                Read
              </Button>
            </div>
          </div>
          <h4 className="text-transparent bg-gradient_text bg-clip-text text-center">
            {person2.name}
          </h4>
        </div>
        <div>
          <div
            className="group relative mb-6 flex md:max-w-[400px]"
            onClick={() => setShowQuote3(!showQuote3)}
          >
            <img
              className={
                "rounded-[30px] lg:group-hover:opacity-0 " +
                (showQuote3 ? " opacity-0" : " opacity-100")
              }
              src={person3.image}
              loading="lazy"
              alt=""
            />
            <div
              className={
                "absolute flex h-full w-full cursor-default items-center justify-center rounded-[30px] bg-white/[6%] px-6 lg:group-hover:opacity-100" +
                (showQuote3 ? " opacity-100" : " opacity-0")
              }
            >
              <p className="text-center text-white lg:text-base xl:text-lg 2xl:text-2xl">
                {person3.quote}
              </p>
            </div>
            <div className="absolute bottom-5 flex w-full items-center justify-center lg:hidden">
              <Button
                id="recommended_by_quote1_btn"
                onClick={() => setShowQuote3(!showQuote3)}
                btnType="blue"
                className={
                  "!p-2 leading-none tracking-widest" +
                  (showQuote3 ? " hidden" : " inline-block")
                }
              >
                Read
              </Button>
            </div>
          </div>
          <h4 className="text-transparent bg-gradient_text bg-clip-text text-center">
            {person3.name}
          </h4>
        </div>
      </div>
    </div>
  );
}
