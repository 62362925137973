interface ErrorCode {
  [key: string]: string;
}

export const ErrorCodeMap: ErrorCode = {
  /**
   * A user attempted to re-subscribe with an active subscription
   *
   * This will probably never happen since we do not allow users to go to
   * the select plan page with an active subscription
   */
  AS001: "This account already has an active subscription.",
  /**
   * While changing your password, the new password entered matches the current password.
   */
  AS004:
    "The new password must be different than the current password." +
    " Please enter a different new password.",
  /**
   * While logging in, an incorrect password was entered
   */
  AS005:
    "Incorrect password entered. Try entering your password again," +
    " or click 'Forgot?' to reset your password.",
  /**
   * While creating an account, an email belonging to a different account was submitted
   */
  AS020:
    "This email already belongs to an account. Please enter a different email.",
  /**
   * While redeeming a gift, the code entered was entered incorrectly
   */
  AS070: "The gift code entered is invalid.",
  /**
   * While redeeming a gift, the code entered was already redeemed
   */
  AS071: "The gift code entered has already been redeemed.",
  /**
   * While redeeming a gift, the code entered was a subscription is already active
   */
  AS072: "This account already has an active subscription.",
  /**
   * While redeeming a gift, the user has has attempted submitting invalid codes 4 times
   * and this next attempt will be their 5th
   */
  AS073:
    "You have made 4 consecutive failed attempts to redeem a gift code. This is your LAST" +
    " try. If the code fails this time, your account will be locked from trying again for a while.",
  /**
   * While redeeming a gift, the user entered invalid codes 5 times
   */
  AS074:
    "Unfortunately your gift redemption session has been invalidated because of too many" +
    " failed attempts. Please wait awhile before trying again.",
  /**
   * A general error while redeeming a gift code
   */
  AS075:
    "Something went wrong while redeeming the subscription code. Please try again.",
  /**
   * While redeeming a gift, the product identifier is in valid
   * The stripe product may no longer exist
   */
  AS076: "Provided code is not a valid product identifier.",
  /**
   * While redeeming a gift, the code entered belongs to an expired subscription
   * They waited a full year before attempting to redeem
   */
  AS077: "The subscription gift code you are trying to redeem has expired.",
  /**
   * The user already has an active subscription via a gift and cannot modify the
   * subscription
   */
  AS080:
    "Your subscription was a gift. You cannot modify the billing or your subscription.",
  /**
   * While redirecting to the Stripe checkout, the promo code automatically applied
   * is invalid
   */
  AS101:
    "The applied promo code has expired or is invalid. Please contact customer support.",
  /**
   * While redirecting to the Stripe checkout, the user already has an active subscription
   */
  AS102: "This account already has an active subscription.",
  /**
   * While redirecting to the Stripe checkout, the user is not a new subscriber
   */
  AS103:
    "Welcome back! It looks like you've had an account with us before." +
    " Please submit again to re-subscribe!",
  /**
   * While logging in, a user attempted to log in with the incorrect
   * password 4 times and this next attempt will be their 5th
   */
  AS301:
    "You have made 4 consecutive failed attempts to login. This is" +
    " your LAST try. If the login fails this time, your account will" +
    " be locked. Please use the 'Forgot?' option if you would like to reset your password.",
  /**
   * While logging in, the user attempted to log in with the wrong password 5 times
   */
  AS300:
    "Unfortunately your account has been locked because of too many" +
    " failed login attempts. You must wait 5 minutes until you can try" +
    " again. Please use the 'Forgot?' option if you would like to reset" +
    " your password. After 5 minutes have past, please try again in a new browser window.",
  /**
   * While resetting password, the session expired and the user needs to revalidate
   * to try again
   */
  AS350:
    "Your reset password session has expired. To begin this process again, click 'Forgot?' on the Log In page.",
  /**
   * While resetting password, an invalid validation code was entered
   */
  AS351:
    "Incorrect validation code entered. Please check your email for your valid code and try again." +
    " If you cannot find your code, please click 'Resend Code' to recieve and new code",
  /**
   * While resetting password, an invalid validation code was
   * entered 4 times and this next attempt will be their 5th
   */
  AS353:
    "You have made 4 consecutive failed attempts to validate. This is" +
    " your LAST try. If the validation fails this time, your account will" +
    " be locked. Please use the 'Forgot?' option if you would like to reset your password.",
  /**
   * While resetting password, the user entered 5 invalid validation codes
   */
  AS352:
    "Unfortunately your account has been locked because of too many" +
    " failed validation attempts. You must wait 5 minutes until you can try" +
    " again. After 5 minutes have past, begin this process again by navigating" +
    " back to Log In in a new browser window and click 'Forgot?'.",
  /**
   * While logging in, the entered email does not belong to any account
   */
  AS404:
    "We couldn't find an account with that email. Check that you" +
    " have entered in your email correctly.",
  // Firebase errors
  /**
   * While verifying email, an incorrect validation code was entered
   */
  "auth/invalid-login-credentials":
    "Incorrect password entered. Try entering your password again," +
    " or click 'Forgot?' to reset your password.",
  /**
   * While creating an account, an email belonging to a different account was submitted
   */
  "auth/email-already-in-use":
    "This email address already belongs to an account. Please enter a different email address.",
  /**
   * While logging in, the entered email does not belong to any account
   */
  "auth/invalid-email":
    "We couldn't find an account with that email. Check that you" +
    " have entered in your email correctly.",
  /**
   * The number of requests exceeds the maximum allowed. A simple refresh will allow for more requests.
   */
  "auth/too-many-requests":
    "The number of requests exceeds the maximum allowed. Please wait 30 minutes before attempting again or contact customer support.",
  /**
   * The oob code is now invalid. The forgot password email link expired.
   */
  "auth/invalid-action-code":
    "Your email address was not verified. Please follow the directions sent to" +
    ' your email. You may need begin the process again by clicking "Forgot?" on the Log In page.',
  /**
   * Google SSO: When the user denies Google permission to share details of their Google account.
   */
  "auth/user-cancelled":
    "You must grant Google permission to sign in with Google single sign-on. Otherwise, you can create an account with an email and password.",
  /**
   * Credentials entered were invalid or something when wrong when logging in
   */
  "auth/invalid-credential":
    "The credentials entered are invalid. Please enter your correct credentials or contact customer support.",
  /**
   * Google SSO popup closed
   */
  "auth/popup-closed-by-user":
    "The single sign-on pop-up was closed too early and you were not signed in. Please try again create an account with an email and password.",
};

interface LocalRemoteConfig {
  [key: string]: boolean;
}

export const localRemoteConfig: LocalRemoteConfig = {
  easterDiscount: false,
  enableSubscriptionUpgrade: true,
  firebaseAuth: true,
  firebaseSSO: false,
  showDiscountedPriceOffer: true,
  showTransparentNavigationBar: true,
  showBackgroundVideoInLandingPage: true,
  springSale: false,
  summerSale: false,
  thanksgivingPriceDiscount2023: false,
  useGoogleAnalyticsForUaTracking: true,
  useMixPanelForUATracking: true,
  useSingularWebAnalyticsForAttribution: true,
};
