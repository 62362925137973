import { useEffect, useState, useContext } from "react";
import TagManager from "react-gtm-module";
import Cookies from "js-cookie";

// Contexts //
import Utm from "../../components/analytics/UTM";
import { MixPanelAnalyticsContext } from "../../components/analytics/Analytics";
import { DeviceSessionContext } from "../../components/analytics/DeviceContext";
import {
  Context,
  GetOrCreateSessionAuthContext,
  navigateTo,
} from "../../config/UserAuthContext";
import {
  EnvironmentConfigContext,
  SubscriptionPeriod,
} from "../../config/Environment";

// Assets //
import deanCain from "../../global-assets/assets/dean-cain-headshot.png";
import kevinSorbo from "../../global-assets/assets/kevin-sorbo-headshot.png";
import candaceBure from "../../global-assets/assets/candace-cameron-burre-headshot.png";
import BGLandingImage from "../../global-assets/assets/BG_Image_Landing.webp";
import GamesMarquee from "../../global-assets/assets/games-and-more-marquee.png";
import GetStartedImage from "../../global-assets/assets/get_started_image_2024.png";
import ComicGraphic from "../../global-assets/assets/comic-graphic-combined.png";
import NewsHeadlines from "../../global-assets/assets/news-headlines.png";
import SoliersBackground from "../../global-assets/assets/soldiers-bg.png";
import ThumbDeanCain from "../../global-assets/assets/thumbnail_dean_cain.webp";
import ThumbKevinSorbo from "../../global-assets/assets/thumbnail_kevin_sorbo.webp";
import ThumbTestimonies from "../../global-assets/assets/thumbnail_testimonies.webp";

// Components //
import RecommendedBy from "../../components/common/RecommendedBy";
import TrialAltNavBar from "../../components/trial/TrialAltNavBar";
import Video from "../../components/common/Video";
import Button from "../../components/common/Button";
import TextWithContentSplit from "../../components/common/TextWithContentSplit";
import SectionHeader from "../../components/common/SectionHeader";
import TextWithVideoSection from "../../components/common/TextWithVideoSection";
import PriceOptions from "../../components/common/PriceOptions";
import ValuesChecklist from "../../components/common/ValuesChecklist";

const checkmarkPoints = [
  "Peace of mind with no chats, no in-app ads, no in-app purchases",
  "Fun games, animation, and comics promoting positive values",
  "New content created by experts added every month",
];

export default function Trial2Lander() {
  const mixPanelAnalytics = useContext(MixPanelAnalyticsContext);
  const initialUserContext = GetOrCreateSessionAuthContext();
  const [deviceCtx] = useState<DeviceSessionContext>(
    new DeviceSessionContext()
  );
  const [userContext] = useState(initialUserContext);
  const GlobalUserId = Cookies.get("GlobalUserId");
  const osType = navigator.userAgent;
  const subscriptionInfo = userContext.subscription.sources;

  const config = useContext(EnvironmentConfigContext);
  const monthlyPrice: number =
    config?.productConfig.subscriptionProductPrice.get(
      SubscriptionPeriod.Monthly
    ) || 0;
  const annualDiscountPrice6999: number =
    config?.productConfig.subscriptionProductPrice.get(
      SubscriptionPeriod.AnnualDiscounted6999
    ) || 0;

  Cookies.remove(Context.Pk);

  const celebDeanCain = {
    image: deanCain,
    name: "Dean Cain",
    quote: `"TruPlay is a team who are fully committed to making
    high quality, engaging entertainment for today's kids.
    They've developed games that will attract and retain
    their attention. Games with positive messages that
    reinforce good values."`,
  };

  const celebCandaceBure = {
    image: candaceBure,
    name: "Candace Cameron Burre",
    quote: `“If you're a parent or someone with young kids in your 
    life, you want your kids to be safe online. With TruPlay, kids 
    have fun in a world of safe and original content.”`,
  };

  const celebKevinSorbo = {
    image: kevinSorbo,
    name: "Kevin Sorbo",
    quote: `“This is more than just an entertainment platform - it's 
    a sanctuary of hope and faith, a place where families can strengthen 
    their bonds through God's word. I love what TruPlay stands for and 
    the positive impact it offers to its users.”`,
  };

  useEffect(() => {
    Cookies.set(Context.FreeTrial, "true");

    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        page_name: "marketing: landing: fear_based-2",
        page_type: "marketing",
        os_type: deviceCtx.os.name,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    let utm = new Utm(mixPanelAnalytics);
    utm.track("Web: Fear-Based-Trial-2-Page-Viewed", deviceCtx);
  }, [
    deviceCtx,
    mixPanelAnalytics,
    userContext.subscription.sources,
    GlobalUserId,
  ]);

  const handleUserRegNavigate = (linkURL: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        content_type: "button cta",
        os_type: osType,
        link_name: "user-registration",
        link_url: linkURL,
        plan_type: subscriptionInfo[0] || "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    navigateTo(linkURL);
  };

  const contentTextHero = (
    <div className="flex w-full flex-col items-center justify-center gap-12">
      <h2 className="hidden text-center text-white lg:inline lg:text-left">
        Discover the Safe Gaming App Parents Trust!
      </h2>
      <h4 className="text-center text-white lg:hidden lg:text-left">
        Discover the Safe Gaming App Parents Trust!
      </h4>
      <div className="hidden w-full lg:inline ">
        <Button
          id="trial2_screen_time_cta"
          onClick={() => handleUserRegNavigate("/user-registration")}
          btnType="green"
          className="self-center !px-10 lg:self-start"
        >
          Redeem Your Free Week
        </Button>
      </div>
    </div>
  );

  const contentTextIgnite = (
    <div className="flex w-full flex-col items-center justify-center gap-6">
      <div className="flex flex-col gap-2">
        <h4 className="text-transparent bg-gradient_text bg-clip-text text-center lg:text-left">
          TruPlay Games - A Beacon of Hope
        </h4>
        <p className="relative m-0 overflow-hidden text-center text-lg font-normal text-white lg:!line-clamp-none lg:text-left lg:text-xl lg:font-semibold">
          Our mission is to offer a world-class, faith-based entertainment
          platform that includes games, animation, and comics - all adhering to
          Biblical truths.
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <h4 className="text-transparent bg-gradient_text bg-clip-text text-center lg:text-left">
          Designed by Experts, Specifically for Children
        </h4>
        <p className="relative m-0 overflow-hidden text-center text-lg font-normal text-white lg:!line-clamp-none lg:text-left lg:text-xl lg:font-semibold">
          It's free from ads, additional in-app purchases, and chat rooms,
          ensuring a safe and wholesome environment for children.
        </p>
      </div>
    </div>
  );

  const contentTextComic = (
    <div className="flex w-full flex-col items-center justify-center gap-6">
      <div className="flex flex-col gap-2">
        <h4 className="text-transparent bg-gradient_text bg-clip-text text-center lg:text-left">
          Explore the World of Rhym
        </h4>
        <p className="relative m-0 overflow-hidden text-center text-lg font-normal text-white lg:!line-clamp-none lg:text-left lg:text-xl lg:font-semibold">
          Over the course of several games, animations, and digital comics, all
          intentionally intertwined into the RhymVerse meta-narrative, our
          players will get to know Maple, Lucas, Ava, Benjamin, and the others,
          as they fight to save their world from villains who seek to rule over
          it.
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <h4 className="text-transparent bg-gradient_text bg-clip-text text-center lg:text-left">
          More Than Just One Game
        </h4>
        <p className="relative m-0 overflow-hidden text-center text-lg font-normal text-white lg:!line-clamp-none lg:text-left lg:text-xl lg:font-semibold">
          Our games do more than entertain. We directly touch users' minds and
          hearts by embedding Biblical truths into exciting and creative
          storylines.
        </p>
      </div>
    </div>
  );

  const contentTextNews = (
    <div className="flex flex-col gap-6">
      <p className="m-0 text-center text-white lg:text-left">
        Did you know that an average American child spends over 52 hours a week
        on screen time, with games being their primary source of entertainment?
        Today, more money and time is spent on games than any other form of
        media and entertainment, highlighting the powerful influence of gaming
        in shaping young minds.
      </p>
      <p className="m-0 text-center text-white lg:text-left">
        Value-less content served through social media, combined with violent
        and misguiding games, is the reason why America's children are burdened
        with anxiety, depression, and suicide like never before in our nation's
        history.
      </p>
      <p className="m-0 text-center text-white lg:text-left">
        TruPlay Games directly combats this and is putting America on the right
        track again. See what our users have to say about TruPlay Games below.
      </p>
    </div>
  );

  return (
    <div id="test_data_trial_alt_page">
      <TrialAltNavBar />
      <div className="relative overflow-hidden">
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_65%_3%,#20568b_0%,transparent_10%)] opacity-80" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_50%_55%,#20568b_0%,#00203f_25%,transparent_100%)] opacity-70" />
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_50%_77%,#68e0cf_0%,transparent_10%)] opacity-50" />
        <div className="mx-auto flex max-w-[1440px] flex-col items-center justify-center gap-8 px-5 pb-16 pt-28 sm:px-6 md:gap-12 md:px-7 lg:gap-32 lg:pb-32 lg:pt-40">
          <TextWithContentSplit
            headerText="Can Your Child's Screen Time Transform Their Future and Faith?"
            subtext={contentTextHero}
            content={
              <img src={GamesMarquee} alt="TruPlay Games" loading="eager" />
            }
            subtextColor="text-white"
            contentLocation="right"
            textPositionOnMobile="above"
          />
          <Button
            id="trial2_screen_time_cta_mobile"
            onClick={() => handleUserRegNavigate("/user-registration")}
            btnType="green"
            className="self-center !px-10 lg:hidden lg:self-start"
          >
            Redeem Your Free Week
          </Button>
          <div className="flex w-full flex-col gap-6 text-center">
            <SectionHeader
              headerType="h2"
              headerText="Faith in God is Rapidly Declining"
              starLocation="right"
            />
            <SectionHeader
              headerType="h3"
              headerText="Less than Half of America Goes to Church"
              textColor="text-white"
              subtext="
              With less than half of America going to church regularly for the
              first time since the 1940s, the foundational values of our society
              are shifting. In a world where truth seems obscured and God's
              teachings are increasingly sidelined, what future awaits our
              children?"
            />
          </div>
          <div className="flex w-full flex-col gap-6">
            <SectionHeader
              headerType="h2"
              headerText="Ignite your Child's Faith in God"
              starLocation="right"
            />
            <TextWithContentSplit
              headerText=""
              subtext={contentTextIgnite}
              content={
                <img src={GetStartedImage} alt="Get Started" loading="lazy" />
              }
              contentLocation="left"
              textPositionOnMobile="below"
              contentPercentage={70}
            />
          </div>
          <div className="relative flex w-full flex-col gap-6 lg:-mt-12 lg:pb-8 xl:pb-32">
            <TextWithContentSplit
              headerText=""
              subtext={contentTextComic}
              content={
                <div className="absolute -z-[1] lg:-right-[20px] lg:-top-[50px] lg:w-[800px] xl:-right-[60px] xl:-top-[110px] xl:w-[1000px]">
                  <img src={ComicGraphic} alt="" loading="lazy" />
                </div>
              }
              contentLocation="right"
              textPositionOnMobile="below"
              hideContentOnMobile={true}
              contentPercentage={65}
            />
          </div>
          <div className="flex w-full flex-col gap-6">
            <div className="lg:hidden">
              <SectionHeader
                headerType="h2"
                headerText="Discover the Difference with TruPlay Games"
              />
            </div>
            <TextWithVideoSection
              headerText="Discover the Difference with TruPlay Games"
              headerTextColor="text-transparent bg-gradient_text bg-clip-text"
              hideHeaderOnMobile
              contentText={
                <Button
                  id="trial2_discover_diff_cta_mobile"
                  onClick={() => handleUserRegNavigate("/user-registration")}
                  btnType="green"
                  className="!px-10"
                >
                  Redeem Your Free Week
                </Button>
              }
              contentTextColor="text-white"
              videoUrl="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/dean_cain%20(720p).mp4"
              videoId="872592906"
              videoThumbnail={ThumbDeanCain}
              videoShareUrl="https://vimeo.com/872592906"
              videoPosition="right"
              videoSectionTestId="trial2_dean_cain"
              textPositionOnMobile="below"
              headerType="h2"
              showCustomPlayButton={false}
            />
          </div>
          <div className="relative mx-auto flex w-full flex-col items-center gap-6 text-center">
            <SectionHeader headerType="h2" headerText="Choose Your Plan" />
            <PriceOptions
              annualPrice={annualDiscountPrice6999}
              monthlyPrice={monthlyPrice}
              ctaButton={
                <Button
                  id="pricing_options_cta_1"
                  onClick={() => handleUserRegNavigate("/user-registration")}
                  btnType="green"
                  className="!px-10"
                >
                  Redeem Your Free Week
                </Button>
              }
            />
          </div>
          <div className="flex w-full flex-col gap-6">
            <SectionHeader
              headerType="h2"
              headerText="Building Character, One Game at a Time"
              starLocation="right"
            />
            <TextWithContentSplit
              headerText=""
              subtext={contentTextNews}
              content={<img src={NewsHeadlines} alt="" loading="lazy" />}
              contentLocation="right"
              textPositionOnMobile="above"
            />
          </div>
          <div className="flex w-full flex-col gap-6 text-center">
            <SectionHeader
              headerType="h2"
              headerText="TruPlay Games: A Must-have for Parents who want Games that Build
              Character and Faith"
              subtext="TruPlay Games emerges as the fortress against this tide. Our
              vision is to build a phenomenal entertainment platform with
              world-class content - games, animation, comics - all rooted in
              God's truth, free from ads, purchases, and risky chatrooms. It's a
              safe digital haven for your children."
            />
          </div>
          <div className="flex w-full flex-col gap-6">
            <TextWithContentSplit
              headerText="Safe Screen Time for Kids"
              subtext={<ValuesChecklist points={checkmarkPoints} />}
              content={
                <img src={GamesMarquee} alt="TruPlay Games" loading="eager" />
              }
              contentLocation="left"
              textPositionOnMobile="below"
            />
          </div>
          <RecommendedBy
            title="Trusted by..."
            person1={celebDeanCain}
            person2={celebCandaceBure}
            person3={celebKevinSorbo}
          />
          <div className="mx-auto flex w-full max-w-[850px] flex-col">
            <Video
              url="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/kevin_sorbo%20(720p).mp4"
              shareUrl="https://vimeo.com/905745758"
              shareSmall
              controls
              playsInline
              thumbnail={ThumbKevinSorbo}
            />
          </div>
          <div className="relative mx-auto flex w-full flex-col items-center gap-6 text-center">
            <SectionHeader headerType="h2" headerText="Choose Your Plan" />
            <PriceOptions
              annualPrice={annualDiscountPrice6999}
              monthlyPrice={monthlyPrice}
              ctaButton={
                <Button
                  id="pricing_options_cta_1"
                  onClick={() => handleUserRegNavigate("/user-registration")}
                  btnType="green"
                  className="!px-10"
                >
                  Redeem Your Free Week
                </Button>
              }
            />
          </div>
        </div>
      </div>
      <div className="relative overflow-hidden">
        <div className="mx-auto flex max-w-[1440px] flex-col items-center justify-center gap-8 px-5 pb-24 pt-12 sm:px-6 md:gap-12 md:px-7 lg:gap-20 lg:pb-24 lg:pt-12">
          <div className="mx-auto max-w-[900px]">
            <SectionHeader
              headerType=""
              headerText=""
              subtext="See what parents are saying about the all new Christian
              entertainment platform."
            />
          </div>
          <div className="mx-auto flex w-full max-w-[850px] flex-col">
            <Video
              url="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/testimonies%20(720p).mp4"
              shareUrl="https://vimeo.com/896306896"
              shareSmall
              controls
              playsInline
              thumbnail={ThumbTestimonies}
              borderColor="bg-[#00203f66]"
            />
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1]">
          <img
            src={SoliersBackground}
            alt=""
            loading="lazy"
            className="h-full w-full object-cover object-center"
          />
        </div>
      </div>
      <div className="relative overflow-hidden">
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-[radial-gradient(circle_at_50%_100%,#68e0cf_0%,transparent_50%)] opacity-30" />
        <div className="mx-auto flex max-w-[1440px] flex-col items-center justify-center px-5 py-16 sm:px-6 md:px-7 lg:py-24">
          <div className="relative mx-auto flex w-full flex-col items-center gap-6 text-center">
            <SectionHeader headerType="h2" headerText="Choose Your Plan" />
            <PriceOptions
              annualPrice={annualDiscountPrice6999}
              monthlyPrice={monthlyPrice}
              ctaButton={
                <Button
                  id="pricing_options_cta_1"
                  onClick={() => handleUserRegNavigate("/user-registration")}
                  btnType="green"
                  className="!px-10"
                >
                  Redeem Your Free Week
                </Button>
              }
            />
          </div>
        </div>
      </div>
      <div className="absolute bottom-auto left-0 right-0 top-0 -z-[3] mx-auto max-w-none overflow-hidden regmd:flex">
        <img
          src={BGLandingImage}
          loading="eager"
          sizes="100vw"
          alt=""
          className="relative -z-[1] w-full min-w-[1920px]"
        />
      </div>
    </div>
  );
}
