import {
  GetEmailAddress,
  isAuthenticatedWithValidToken,
} from "../../../config/UserAuthContext";

// Components
import NavBar from "../NavBar/NavBar";
import GiftASubNavbar from "../GiftASubNavbar/GiftASubNavbar";
import TruPlayAppsNav from "../TruPlayAppsNav/TruPlayAppsNav";
import MinimalNav from "../MinimalNav";
import NotificationBanner from "../../common/NotificationBanner";

interface TopNavBarProps {
  navType?: string;
  showMobileLogo?: boolean;
  showBanner?: boolean;
}

/**
 *
 * @constructor
 */
const TopNavbar = ({
  navType,
  showMobileLogo = true,
  showBanner = false,
}: TopNavBarProps) => {
  let isAuthenticated = isAuthenticatedWithValidToken();
  const EmailAddress: string = GetEmailAddress() || "";

  let content = null;

  if (navType === "gift") {
    content = (
      <GiftASubNavbar
        isAuthenticated={isAuthenticated}
        emailAddress={EmailAddress}
      />
    );
  } else if (navType === "truplay-apps") {
    content = <TruPlayAppsNav />;
  } else if (navType === "minimal") {
    content = (
      <MinimalNav
        isAuthenticated={isAuthenticated}
        emailAddress={EmailAddress}
      />
    );
  } else {
    content = (
      <NavBar
        isAuthenticated={isAuthenticated}
        emailAddress={EmailAddress}
        showMobileLogo={showMobileLogo}
        light={navType === "light"}
      />
    );
  }

  return (
    <>
      <link href="https://fonts.googleapis.com" rel="preconnect" />
      <link
        href="https://fonts.gstatic.com"
        rel="preconnect"
        crossOrigin="anonymous"
      />
      <div
        className={
          "fixed bottom-auto left-0 right-0 top-0 z-[999]" +
          (navType !== "light" ? " bg-global_nav_bg" : " bg-white")
        }
      >
        {showBanner && <NotificationBanner />}
        <div className="mx-auto max-w-[1536px]">{content}</div>
      </div>
    </>
  );
};

export default TopNavbar;
