import Button from "./Button";
import AccentStars2 from "../../global-assets/assets/Accent_Stars_02.svg";

interface IHeroVisualTextSection {
  sectionId: string;
  headerText: string;
  headerTextColor?: string;
  headerStyles?: string;
  contentText: string | JSX.Element;
  contentTextColor?: string;
  visualComponent: JSX.Element;
  visualPosition: string;
  ctaText?: string;
  ctaAction?: () => void;
}

export default function HeroVisualTextSection({
  sectionId,
  headerText,
  headerTextColor = "text-transparent bg-gradient_text bg-clip-text",
  headerStyles = "",
  contentText,
  contentTextColor = "text-indigo-200",
  visualComponent,
  visualPosition,
  ctaText,
  ctaAction,
}: IHeroVisualTextSection) {
  return (
    <div className="flex flex-col gap-8 regmd:gap-12 md:gap-16 lg:flex-row lg:gap-12 2xl:gap-20">
      <div
        className={
          "flex flex-col gap-4 lg:flex-[0.67_1_0] lg:items-center lg:justify-center" +
          (visualPosition === "left" ? " order-first" : " order-last")
        }
      >
        <h1
          className={
            "lg:hidden" + (" " + headerTextColor) + (" " + headerStyles)
          }
        >
          {headerText}
        </h1>
        <div className="relative w-full">{visualComponent}</div>
      </div>
      <div className="flex flex-col justify-center gap-4 lg:flex-[0.33_1_0] lg:gap-6">
        <div className="flex flex-col gap-4">
          <h1
            className={
              "hidden" + (" " + headerTextColor) + (" " + headerStyles)
            }
          >
            {headerText}
            <img
              src={AccentStars2}
              alt="Accent Stars"
              loading="eager"
              className="absolute -right-[66px] -top-[34px] max-w-[90px] 2xl:-right-[65px] 2xl:-top-[40px] 2xl:max-w-none"
            />
          </h1>
          {typeof contentText === "string" ? (
            <p className={"m-0 text-lg lg:text-xl" + (" " + contentTextColor)}>
              {contentText}
            </p>
          ) : (
            contentText
          )}
        </div>
        {ctaText && (
          <div className="flex flex-col gap-8">
            <Button
              id={sectionId + "_cta_btn"}
              btnType="green"
              onClick={ctaAction}
            >
              {ctaText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
