import TruPlayLogo from "../../global-assets/assets/truplay-logo-large.png";
import Button from "../common/Button";

interface TrialNavBarProps {
  handleScrollToAccountCreation: () => void;
}

const TrialNavBar = ({ handleScrollToAccountCreation }: TrialNavBarProps) => {
  return (
    <>
      <link href="https://fonts.googleapis.com" rel="preconnect" />
      <link
        href="https://fonts.gstatic.com"
        rel="preconnect"
        crossOrigin="anonymous"
      />
      <div className="fixed bottom-auto left-0 right-0 top-0 z-[999] bg-global_nav_bg">
        <div className="relative mx-auto flex h-[104px] max-w-[1536px] items-center justify-between p-6 md:h-[114px]">
          <div className="flex items-center">
            <img
              src={TruPlayLogo}
              alt="Truplay-logo"
              className="w-[7.5rem] sm:w-[9.5rem] lg:w-48"
              id="unauth_tru_logo"
            />
          </div>
          <h3 className="font-weight hidden font-display text-2xl text-white drop-shadow-[0px_0px_16px_#37A6D4] lg:!inline xl:text-3xl">
            Start Your 7-day Free Trial
          </h3>
          <Button
            id="trial_nav_btn"
            onClick={handleScrollToAccountCreation}
            btnType="green"
            className="sm:!px-10"
          >
            <span className="lg:hidden">Free Trial</span>
            <span className="hidden lg:inline">Redeem Your Free Week</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default TrialNavBar;
