import SectionHeader from "../common/SectionHeader";
import ThreeStepsSection from "../common/ThreeStepsSection";

const steps = [
  {
    title: "Sign up for Update!",
    body: "Interested in supporting our mission? Sign up and you will be the first to know when we launch our kickstarter.",
  },
  {
    title: "Check for Kickstarter News",
    body: "Keep an eye out for our launch email and pick the Kickstarter package that is right for you.",
  },
  {
    title: "Share and Support",
    body: "Share with your friends, family members, parents, grandparents, and anyone else who you think would find joy in supporting our mission.",
  },
];

export default function HowItWorks() {
  return (
    <div className="flex flex-col gap-6 lg:gap-20">
      <SectionHeader
        headerType="h2"
        headerText="How It Works"
        starLocation="right"
      />
      <ThreeStepsSection steps={steps} />
    </div>
  );
}
